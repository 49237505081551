import { Field, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useAddAccountContactMutation, useUpdateAccountContactMutation } from "@/redux/toolkit/accountSlice";
import { Button, TextInput } from "../form-fields";
import { Toaster } from '@/components/Toast';

const AddContactSchema = Yup.object({
  firstName: Yup.string().required("First name is a required field"),
  lastName: Yup.string().required("Last name is a required field"),
  email: Yup.string().email("Invalid email address.").required("Email address is a required field."),
  phone: Yup.string(),
  isAccount: Yup.bool(),
  isBilling: Yup.bool(),
});

function AddUpdContactModal({ hide, initialValues = false }) {

  const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const isEditMode = Boolean(initialValues);

  const [addAccountContact] = useAddAccountContactMutation();
  const [updateAccountContact] = useUpdateAccountContactMutation();
  const blankFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isAccount: false,
    isBilling: false,
  };

  const formInitialValues = initialValues ? initialValues : blankFormValues;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async(values, actions) => {
    setIsSubmitting(true);
    try {
      if (isEditMode) {
        await updateAccountContact({data: values, appContactId: initialValues.id, appAccountId}).unwrap();
        Toaster('Account contact updated successfully.');
      } else {
        await addAccountContact({data: values, appAccountId}).unwrap();
        Toaster('Account contact added successfully.');
      }
      actions.resetForm(blankFormValues);
      hide(true);
    }
    catch (error){
      if (isEditMode) {
        Toaster('Updating account contact failed.', 'error');
      } else {
        Toaster('Adding account contact failed.', 'error');
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      key={
        initialValues
          ? initialValues.firstName +
            initialValues.lastName +
            initialValues.email +
            initialValues.phone +
            initialValues.isAccount +
            initialValues.isBilling
          : "AddUptContactForm"
      }
      initialValues={formInitialValues}
      validationSchema={AddContactSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {isEditMode ? `Edit contact` : `Add contact`}
            </h5>
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form className="form needs-validation">
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="First name" name="firstName" type="text" placeholder="First name" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="Last name" name="lastName" type="text" placeholder="Last name" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="Email address" name="email" type="email" placeholder="Email address" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="Phone" name="phone" type="text" placeholder="Phone" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="custom-control custom-checkbox">
                  <Field name="isAccount" type="checkbox" className="custom-control-input" id="accountContact" style={{ zIndex: 1 }} />
                  <label className="custom-control-label" htmlFor="accountContact">
                    Account contact
                  </label>
                </div>
                <small id="passwordHelpBlock" className="form-text text-muted">
                  If checked, this contact will receive account notifications.
                </small>
              </div>
              <div className="form-group mb-3">
                <div className="custom-control custom-checkbox">
                  <Field name="isBilling" type="checkbox" className="custom-control-input" id="billingContact" style={{ zIndex: 1 }} />
                  <label className="custom-control-label" htmlFor="billingContact">
                    Billing contact
                  </label>
                </div>
                <small id="passwordHelpBlock" className="form-text text-muted">
                  If checked, this contact will receive billing notifications.
                </small>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={hide}>
              Cancel
            </button>
            <Button
              label={"Save"}
              className="btn btn-primary"
              dirty={dirty}
              errors={errors}
              isValidating={isValidating}
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Formik>
  );
}

export default AddUpdContactModal;
