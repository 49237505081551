import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, TextInput } from "../../components/form-fields";
import { useUpdateUserMutation } from "@/redux/toolkit/userSlice";

const BasicInfoSchema = Yup.object({
  firstName: Yup.string().required("First name is a required field."),
  lastName: Yup.string().required("Last name is a required field."),
});

function BasicInfo() {
  const initFirstName = useSelector((state) => state.session.user.firstName);
  const initLastName = useSelector((state) => state.session.user.lastName);
  const session = useSelector((state) => state.session);
  const email = useSelector((state) => state.session.user.email);
  const appUserId = useSelector((state) => state.session.user.id);
  const [ updateUser, { isLoading: updatingUser } ] = useUpdateUserMutation();
  const onSubmit = (values) => {
    updateUser({appUserId, data:{ ...values, email }});
    let { id, createdAt } = session.user;
    window.analytics.identify(id, { email, firstName: values.firstName, lastName: values.lastName, name: `${values.firstName} ${values.lastName}`, createdAt, id });
  }

  useEffect(() => {
    let segmentData = {
      title: `User Profile | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("User Profile", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    })
  }, [session.account, session.subscription, session.user]);

  return (
    <div className="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
      <section className="mb-5">
        <p className="lead">Basic info</p>
        <div className="card">
          <div className="card-body">
            <Formik
              key={initFirstName + initLastName}
              initialValues={{
                firstName: initFirstName ?? "",
                lastName: initLastName ?? "",
              }}
              validationSchema={BasicInfoSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, errors, isValidating }) => (
                <Form className="form needs-validation">
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput label="First name" name="firstName" type="text" placeholder="First name" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput label="Last name" name="lastName" type="text" placeholder="Last name" className="form-control" />
                    </div>
                  </div>
                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="First group">
                      <Button label={"Save"} dirty={dirty} errors={errors} isValidating={isValidating} isSubmitting={updatingUser} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BasicInfo;
