import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useRemoveUserMutation } from "@/redux/toolkit/userSlice";
import { Toaster } from '@/components/Toast';
import { Button, TextInput } from "../form-fields";

function RemoveUserModal({ hide, user, method }) {
  const name = user.firstName + " " + user.lastName;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const [removeUser] = useRemoveUserMutation();


  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await removeUser({ accountUser: "removeAccountUser" === method, appAccountId, appUserId: user.id }).unwrap();
      hide(true);
      Toaster('User removed successfully.');
    } catch (error) {
      Toaster('Removing user failed.', "error");
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type user's full name correctly.", (value) => name === value),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Remove user
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1"></i>
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="alert alert-primary" role="alert">
                      <p className="m-0">
                        Are you sure you want to remove user {name} from{" "}
                        <b>
                          <u>all accounts</u>
                        </b>{" "}
                        They will no longer have access or be able to collaborate with other users. Products will still keep all of this user's
                        content and contributions.
                      </p>
                    </div>
                    <p>Please type the user's full name to confirm:</p>
                    <div className="form-group mb-3">
                      <TextInput name="name" type="text" placeholder={name} className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Remove user"}
                className="btn btn-danger"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Removing"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default RemoveUserModal;
