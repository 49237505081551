import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, TextInput } from "../../components/form-fields";
import { useUpdateUserPasswordMutation } from "@/redux/toolkit/userSlice";

const PasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/;
const ResetPasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Password is a required field")
    .matches(PasswordRegex, "Minimum 8 characters, 1 uppercase, 1 lowercase, 1 number"),
  newPassword: Yup.string()
    .required("New Password is a required field")
    .matches(PasswordRegex, "Minimum 8 characters, 1 uppercase, 1 lowercase, 1 number"),
  confirmPassword: Yup.string()
    .required("Confirmm New Password is a required field")
    .matches(PasswordRegex, "Minimum 8 characters, 1 uppercase, 1 lowercase, 1 number")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
});

function ResetPassword() {
  const [ updateUserPassword, { isLoading: updatingUser } ] = useUpdateUserPasswordMutation();

  const session = useSelector((state) => state.session);
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  useEffect(() => {
    let segmentData = {
      title: `User Password | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("User Password", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    })
  }, [session.user, session.account, session.subscription]);

  const appUserId = useSelector((state) => state.session.user?.id);

  const onSubmit = (values, actions) => {
    updateUserPassword({
      data: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
      appUserId,
    });
    actions.resetForm(initialValues);
  };

  return (
    <div className="tab-pane fade show active" id="changepassword" role="tabpanel" aria-labelledby="changepassword-tab">
      <section className="mb-5">
        <p className="lead">Change password</p>
        <div className="card">
          <div className="card-body">
            <Formik initialValues={initialValues} validationSchema={ResetPasswordSchema} onSubmit={onSubmit}>
              {({ dirty, errors, isValidating }) => (
                <Form className="form needs-validation">
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput label="Old password" name="oldPassword" type="password" placeholder="Old password" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput label="New password" name="newPassword" type="password" placeholder="New password" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput
                        label="Confirm New password"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm New password"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="First group">
                      <Button label={"Save"} dirty={dirty} errors={errors} isValidating={isValidating} isSubmitting={updatingUser} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ResetPassword;
