import { api } from "@/redux/toolkit/apiSlice";
import { Toaster } from '@/components/Toast';

export const extendedApiSlice1 = api.injectEndpoints({
  endpoints: (builder) => ({
    getUseCases: builder.query({
      query: () => {
        return  `/manage/usecases`;
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster('Failed to fetch all use cases', 'error');
        }
      },
      transformResponse: ({ payload }) => {
        let sortedUseCases = [];
        if(payload && payload.useCases && payload.useCases.length) {
          sortedUseCases = payload.useCases.sort((usecase, preUseCase) => usecase.category.localeCompare(preUseCase.category) || usecase.name.localeCompare(preUseCase.name));
        }
        return {
          useCases: payload.useCases,
          sortedUseCases: sortedUseCases,
        };
      },
      providesTags: ["UseCases"],
    }),
    addUseCase: builder.mutation({
      query: ({ appUserId, data }) => ({
        url: `/manage/usecases`,
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Use case added successfully.");
        } catch (err) {
          Toaster("Adding use case failed.", "error");
        }
      },
      invalidatesTags: ["UseCases"],
    }),
    removeUseCase: builder.mutation({
      query: ({ useCaseId, appUserId }) => ({
        url: `/manage/usecases/${useCaseId}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Use case removed successfully.");
        } catch (err) {
          Toaster("Removing use case failed.", "error");
        }
      },
      invalidatesTags: ["UseCases"],
    }),
    updateUseCase: builder.mutation({
      query: ({ appUserId, useCaseId, data }) => ({
        url: `/manage/usecases/${useCaseId}`,
        method: "PUT",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Use case successfully updated.");
        } catch (err) {
          Toaster("Use case update failed.", "error");
        }
      },
      invalidatesTags: ["UseCases"],
    }),
  }),
});

export const {
  useGetUseCasesQuery,
  useRemoveUseCaseMutation,
  useUpdateUseCaseMutation,
  useAddUseCaseMutation
} = extendedApiSlice1;