import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../form-fields";
import { useRemoveUseCaseMutation } from "@/redux/toolkit/useCasesSlice";

function RemoveUseCaseModal({ hide, useCase, method }) {
  const [removeUseCase] = useRemoveUseCaseMutation();
  const name = useCase.name;
  const [isSubmitting, setIsSubmitting] = useState(false);


  const onSubmit = async() => {
    setIsSubmitting(true);
    await removeUseCase({useCaseId: useCase.id});
    hide(true);
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type DELETE correctly.", (value) => "DELETE" === value),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Remove use case
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1" />
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="alert alert-primary" role="alert">
                      <p className="m-0">
                        Are you sure you want to remove use case <b>
                        <u>{name} ?</u>
                      </b><br />
                         This action is immediate and cannot be undone.
                      </p>
                    </div>
                    <p>Type <strong>DELETE</strong> to confirm:</p>
                    <div className="form-group mb-3">
                      <TextInput name="name" type="text" placeholder="DELETE" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Remove"}
                className="btn btn-danger"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Removing"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default RemoveUseCaseModal;
