import { Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, TextInput } from '../form-fields';
import { useResetUserMFAMutation } from "@/redux/toolkit/accountSlice";
import { Toaster } from '@/components/Toast';

const UserMFASchema = Yup.object({
	confirmation: Yup.string().test(
		'is-reset',
		(d) => `${d.path} is not 'reset'`,
		(value) => value === 'reset'
	),
});

function ResetUserMFAModal({ hide, user, userSuperAdmin= false }) {

  const [resetUserMFA] = useResetUserMFAMutation();
  const userInfo = useSelector((state) => state.session.user);
	const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
	const initialValues = { confirmation: '' };

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async (values, actions) => {
		setIsSubmitting(true);
		try {
			await resetUserMFA({userSuperAdmin, appAccountId, appUserId: user.id}).unwrap();
			hide(true);
      let { id, email, firstName, lastName, createdAt } = userInfo;
      window.analytics.identify(id, { email, firstName, lastName, name: `${firstName} ${lastName}`, createdAt, id });
      Toaster('User MFA reset successfully.');
		} catch (error) {
      Toaster('User MFA reset failed.', 'error');
		}
		setIsSubmitting(false);
	};

	return (
		<Formik
			key={user.id}
			initialValues={initialValues}
			validationSchema={UserMFASchema}
			onSubmit={onSubmit}
		>
			{({ dirty, errors, isValidating, handleSubmit }) => (
				<>
					<div className='modal-header'>
						<h5 className='modal-title' id='staticBackdropLabel'>
							Reset MFA for {user.firstName} {user.lastName}
						</h5>
						<button type='button' className='close' onClick={() => hide()}>
							<span aria-hidden='true'>
								<i className='fal fa-times mt-1' />
							</span>
						</button>
					</div>
					<div className='modal-body'>
						{/* <p>{`Reset MFA for user ${user.firstName} ${user.lastName}.`}</p> */}
						<form
							className='form needs-validation'
							action='#'
							style={{ width: '420px' }}
							noValidate
						>
							<div className='form-group mb-3'>
								<label htmlFor='confirmation'>
									Do you really want to reset user's MFA?
								</label>
								<TextInput
									name='confirmation'
									type='text'
									placeholder='reset'
									className='form-control'
								/>
							</div>
						</form>
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-outline-dark'
							onClick={() => hide()}
						>
							Cancel
						</button>
						<Button
							label={'Reset'}
							className='btn btn-primary'
							dirty={dirty}
							errors={errors}
							isValidating={isValidating}
							isSubmitting={isSubmitting}
							onClick={handleSubmit}
						/>
					</div>
				</>
			)}
		</Formik>
	);
}

export default ResetUserMFAModal;
