import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetUseCasesQuery } from "@/redux/toolkit/useCasesSlice";
import { useUpdateAccountMutation } from "@/redux/toolkit/accountSlice";
import { Table } from "../Table";

function Settings() {
  const appAccountId = useSelector(
    (state) => state.accounts.getAccount.data?.id
  );
  const useCaseOverride = useSelector(
    (state) => state.accounts.getAccount.data?.useCaseOverride
  );
  const selectedUseCases = useSelector(
    (state) => state.accounts.getAccount.data?.useCases
  );
  const [updateAccount] = useUpdateAccountMutation();
  const session = useSelector((state) => state.session);
  const [useCaseSetting, setUseCaseSetting] = useState(useCaseOverride);
  const [useCaseChecked, setUseCaseChecked] = useState(
    selectedUseCases.map((usecase) => usecase.id)
  );

  const {
    data: { sortedUseCases: UseCases } = {},
    isLoading,
    isSuccess: isFetched,
  } = useGetUseCasesQuery();

  useEffect(() => {
    let segmentData = {
      title: `Account Settings | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId:
        session.account && session.account.id ? session.account.id : "",
      accountName:
        session.account && session.account.company
          ? session.account.company
          : "",
      accountType:
        session.account && session.account.type ? session.account.type : "",
      subscriptionId:
        session.subscription && session.subscription.id
          ? session.subscription.id
          : "",
      subscriptionChargebeeId:
        session.subscription && session.subscription.chargebeeId
          ? session.subscription.chargebeeId
          : "",
      subscriptionStatus:
        session.subscription && session.subscription.status
          ? session.subscription.status
          : "",
      planId:
        session.subscription &&
        session.subscription.plan &&
        session.subscription.plan.id
          ? session.subscription.plan.id
          : "",
      planChargebeeId:
        session.subscription &&
        session.subscription.plan &&
        session.subscription.plan.chargebeeId
          ? session.subscription.plan.chargebeeId
          : "",
    };
    if (
      session.account &&
      session.account.organisation &&
      session.account.organisation.id
    ) {
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page(
      "Account Settings",
      {
        ...segmentData,
      },
      {
        groupId:
          session.account && session.account.id ? session.account.id : "",
      }
    );
  }, [session.user, session.account, session.subscription]);

  const onChangeUseCaseState = async (status, useCase) => {
    const useCaseIds = selectedUseCases.map((usecase) => usecase.id);

    if (!(useCase && useCase.id)) {
      setUseCaseSetting(status);
      setUseCaseChecked(useCaseIds);
      const updateData = status
        ? { useCaseOverride: status, useCaseIds }
        : { useCaseOverride: status };
      await updateAccount({ appAccountId, data: updateData });
      setUseCaseChecked(useCaseIds);
      return;
    }
    if (status) {
      setUseCaseChecked([...useCaseIds, useCase.id]);
      await updateAccount({
        appAccountId,
        data: {
          useCaseOverride: useCaseSetting,
          useCaseIds: [...useCaseIds, useCase.id],
        },
      });
    } else {
      const index = useCaseIds.indexOf(useCase.id);
      useCaseIds.splice(index, 1);
      setUseCaseChecked(useCaseIds);
      await updateAccount({
        appAccountId,
        data: {
          useCaseOverride: useCaseSetting,
          useCaseIds: useCaseIds,
        },
      });
    }
  };

  const checkBoxStatus = (usecase) => {
    return useCaseChecked.some(
      (selectedUseCase) => selectedUseCase === usecase.id
    );
  };

  return (
    <div
      className="tab-pane fade show active"
      id="managesetting"
      role="tabpanel"
      aria-labelledby="managesetting-tab"
    >
      <section className="mb-5">
        <p className="lead">Use cases</p>
        <div className="container p-0">
          <div className="custom-control custom-switch float-right mb-4">
            <input
              className="custom-control-input"
              name="useCase"
              id="userCasecheckboxes"
              type="checkbox"
              defaultChecked={useCaseSetting}
              onChange={(e) => {
                onChangeUseCaseState(e.target.checked);
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="userCasecheckboxes"
            >
              Override use cases
            </label>
          </div>
          <div className="table-responsive my-4">
            <Table
              isLoading={isLoading}
              isFetched={isFetched}
              dataSource={UseCases}
              colMapper={[
                {
                  title: "Name",
                  keyName: "name",
                  cellRender: ({ name, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {name}
                      <br />
                      <small className="text-muted">
                        <b>ID</b> {id}
                      </small>
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "Category",
                  keyName: "category",
                  cellRender: ({ category, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {category}
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "",
                  cellRender: (useCase, key) => (
                    <td className="align-middle" key={key}>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          name="useCase"
                          id={`userCasecheckbox-${key}`}
                          type="checkbox"
                          checked={checkBoxStatus(useCase)}
                          onChange={(e) => {
                            if (
                              useCase.name.toLowerCase() !== "other" &&
                              useCaseSetting
                            ) {
                              onChangeUseCaseState(e.target.checked, useCase);
                            }
                          }}
                          disabled={
                            useCase?.name?.toLowerCase() === "other" ||
                            !useCaseSetting
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`userCasecheckbox-${key}`}
                        />
                      </div>
                    </td>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Settings;
