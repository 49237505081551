import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchAccountUsers,
} from "../../redux/actions/accounts-action-types";
import {
  useAddAccountSubscriptionUserMutation,
  useDeleteAccountSubscriptionUserMutation,
  useGetAccountSubscriptionQuery,
  useGetAccountSubscriptionUsersQuery
} from "@/redux/toolkit/accountSlice";
import { Table } from "../Table";
import AsyncSelect from "react-select/async";
import { Toaster } from '@/components/Toast';

function ManageSeatsModal({ hide, subscriptionItem }) {
  const dispatch = useDispatch();
  const ref = useRef();

  const [addUserInput, setAddUserInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDropDown, setShowDropDown] = useState(null);

  const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const {data: { subscription }={}, isLoading, isSuccess:isFetched } = useGetAccountSubscriptionQuery({
    appAccountId, appSubscriptionId: subscriptionItem.id
  });
  const {data: { subscriptionUsers }={}, isLoading: isLoadingUsers, isSuccess:isFetchedUsers } = useGetAccountSubscriptionUsersQuery({
    appAccountId, appSubscriptionId: subscriptionItem.id
  });

  const [deleteAccountSubscriptionUser] = useDeleteAccountSubscriptionUserMutation();
  const [addAccountSubscriptionUser] = useAddAccountSubscriptionUserMutation();

  useEffect(()=>{
    const handler = (event) => {
      // if outside of dropdown
      if (ref.current && showDropDown && !ref.current.contains(event.target)) {
        setShowDropDown(null)
      }
    };
    window.addEventListener("click", handler, true);
    return () => window.removeEventListener("click", handler, true);
  },[ref, showDropDown])

  const loadOptions = (inputVal, callback) => {
    const callbackFn = ({ error, result }) => {
      if (result?.payload?.users) {
        callback(result.payload.users);
      }
    };
    dispatch(
      SearchAccountUsers({ appAccountId, orderby: "name", sortby: "desc", query: inputVal, pageIndex: 1, itemsPerPage: 10, callback: callbackFn })
    );
  };


  const onAdd = async (e) => {
    e.preventDefault();
    if (Boolean(selectedUser?.id)) {
      try {
        await addAccountSubscriptionUser({ userId: selectedUser.id, appAccountId, appSubscriptionId: subscriptionItem.id }).unwrap();
        Toaster('Account subscription user added successfully.');
      } catch (error) {
        Toaster('Adding account subscription user failed.', 'error');
      }
      setSelectedUser(null);
    }
  };

  const onRemove = async (e, item) => {
    try{
      await deleteAccountSubscriptionUser({ appAccountId, appSubscriptionId: subscriptionItem.id, appUserId: item.id }).unwrap();
      Toaster('Account subscription user added successfully.');
    } catch (error) {
      Toaster('Removing account subscription user failed.', 'error');
    }
    setSelectedUser(null);
    setShowDropDown(null);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Manage seats
        </h5>
        <button type="button" className="close" onClick={hide}>
          <span aria-hidden="true">
            <i className="fal fa-times mt-1" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div className="modal-body">
        {subscription && (
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <p className="text-muted float-right">
                  <small className="text-primary ">Need more seats? Contact support@dragonflyai.co</small>
                </p>
                <p className="text-muted">
                  <small>Subscription</small>
                </p>
                <h5>{subscription.plan.name}</h5>
                <p>{subscription.plan.description}</p>
                <span className="d-inline-block border bg-white rounded py-2 px-3">
                  {subscription.seatRemaining}/{subscription.seatQuota} seats available
                </span>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <div className="action-box">
                  <form className="form mb-4">
                    <div className="form-group">
                      <h6>Add a user</h6>
                      <div className="form-typeahead dropdown d-relative" style={{ maxWidth: "400px" }}>
                        <div className="input-group">
                          <AsyncSelect
                            inputVal={addUserInput}
                            onInputChange={(val) => {
                              setAddUserInput(val);
                              return val;
                            }}
                            menuIsOpen={Boolean(addUserInput.length)}
                            placeholder={"Search users..."}
                            cacheOptions
                            loadOptions={loadOptions}
                            value={selectedUser}
                            isClearable={true}
                            formatOptionLabel={CustomOption}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                width: "300px",
                                boxSizing: "border-box",
                                border: "1px solid #ced4da",
                                boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(230, 64, 141, 0.25)" : "none",
                                borderColor: state.isFocused ? "#f5b1d0" : "1px solid #ced4da",
                                "&:hover": {
                                  border: "1px solid #ced4da",
                                },
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isFocused ? "#f8f9fa" : "#fff",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }),
                            }}
                            onChange={(selectedOption) => {
                              setSelectedUser(selectedOption);
                            }}
                          />
                          <div className="ml-2">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              id="button-addon2"
                              disabled={!Boolean(selectedUser?.id)}
                              onClick={onAdd}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <h6>Licensed users</h6>
                <div className="table-responsive">
                  <Table
                    orderby={null}
                    sortby={null}
                    isLoading={isLoadingUsers}
                    isFetched={isFetchedUsers}
                    dataSource={subscriptionUsers}
                    colMapper={[
                      {
                        title: "Name",
                        cellRender: ({ firstName, lastName }, key) => (
                          <td className="text-nowrap" key={key}>
                            {`${firstName} ${lastName}`}
                          </td>
                        ),
                      },
                      {
                        title: "Email",
                        keyName: "email",
                      },
                      {
                        title: "",
                        cellRender: (item, key) => (
                          <td className="align-top text-right" key={key}>
                            <div className="dropdown dropdown-sm">
                              <button
                                className="btn btn-link btn-sm"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={e=> item.id === showDropDown ? setShowDropDown(null) : setShowDropDown(item.id)}
                              >
                                <span>
                                  <i className="far fa-lg fa-ellipsis-v" aria-hidden="true" />
                                </span>
                              </button>
                              <div ref={ref} className={`dropdown-menu dropdown-menu-right ${showDropDown === item.id ? "show" : ""}`} aria-labelledby="dropdownMenuButton">
                                <h6 className="dropdown-header">Actions</h6>
                                <span className="dropdown-item text-danger pointer" onClick={(e) => onRemove(e, item)}>
                                  Remove
                                </span>
                              </div>
                            </div>
                          </td>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && !isFetched && (
          <div className="container">
            <div className="loader d-flex flex-column align-items-center justify-content-center my-4">
              <p className="text-muted m-0">
                <i className="fal fa-lg fa-spinner fa-fast-spin" aria-hidden="true"></i>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={hide}>
          Done
        </button>
      </div>
    </>
  );
}

export default ManageSeatsModal;

function CustomOption(item, { context }) {
  const { firstName, lastName, email } = item;
  if (context === "menu") {
    return (
      <div>
        <span className="pointer">
          <span className="d-block">{`${firstName} ${lastName}`}</span>
          <small className="d-block text-muted">{email}</small>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="d-block">{`${firstName} ${lastName}`}</span>
      </div>
    );
  }
}
