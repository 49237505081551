import * as jQuery from "jquery";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetAccountSubscriptionsQuery } from "@/redux/toolkit/accountSlice";
import { ManageSeatsModal, ToggleModal } from "../Modals";
import Pluralize from "../Pluralize";
import { Table } from "../Table";
import methods from "../../utilities/methods";

function Subscriptions() {
  const appAccountId = useSelector(
    (state) => state.accounts.getAccount.data?.id
  );
  const session = useSelector((state) => state.session);
  const {data: { subscriptions }={}, isLoading, isSuccess:isFetched } = useGetAccountSubscriptionsQuery({
    appAccountId
  });

  // useEffect(() => {
  //   dispatch(GetAccountSubscriptions({ appAccountId }));
  // }, [appAccountId, dispatch]);

  useEffect(() => {
    let segmentData = {
      title: `Account Subscriptions | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("Account Subscriptions", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    })
  }, [session.user, session.account, session.subscription]);

  const ProductButtonStyles = {
    width: "2.25em",
    height: "2.25em",
    lineHeight: "1.25em",
    background: "transparent",
    marginRight: "5px",
  };

  const StatusMapping = {
    active: {
      label: "Active",
      class: "success",
    },
    nonrenewing: {
      label: "Non Renewing",
      class: "success",
    },
    intrial: {
      label: "In Trial",
      class: "success",
    },
    cancelled: {
      label: "Cancelled",
      class: "danger",
    },
  };

  React.useEffect(() => {
    const $ = jQuery;
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, [isLoading, isFetched, subscriptions]);

  return (
    <div
      className="tab-pane fade show active"
      id="subscriptions"
      role="tabpanel"
      aria-labelledby="subscriptions-tab"
    >
      <section className="mb-5">
        <p className="lead">Subscriptions</p>
        <p>
          <small className="text-muted">
            To upgrade contact support@dragonflyai.co.
          </small>
        </p>

        <div className="table-responsive my-4">
          <Table
            orderby={null}
            sortby={null}
            isLoading={isLoading}
            isFetched={isFetched}
            dataSource={subscriptions}
            colMapper={[
              {
                title: "Plan",
                keyName: "plan",
                cellRender: ({ id, plan: { name } }, key) => (
                  <td className="" key={key}>
                    {name}
                    <br />
                    <small className="text-muted">
                      <b>ID</b> {id}
                    </small>
                  </td>
                ),
                width: "350px",
              },
              {
                title: "Quantity",
                keyName: "quantity",
                cellRender: ({ seatQuota, seatRemaining }, key) => (
                  <td className="text-nowrap" key={key}>
                    <Pluralize singular={"seat"} count={seatQuota} />
                    <br />
                    <small className="text-muted">
                      {seatRemaining} available
                    </small>
                  </td>
                ),
              },
              {
                title: "Status",
                keyName: "status",
                cellRender: ({ status }, key) => (
                  <td className="text-nowrap" key={key}>
                    <span
                      className={`d-inline-block border border-${StatusMapping[status]?.class} text-${StatusMapping[status]?.class} py-1 px-2`}
                    >
                      {StatusMapping[status]
                        ? StatusMapping[status].label
                        : methods.ucFirstLetter(status)}
                    </span>
                  </td>
                ),
              },
              {
                title: "Products",
                keyName: "products",
                cellRender: ({ plan: { productAccess } }, key) => (
                  <td className="align-top text-nowrap" key={key}>
                    {productAccess.indexOf("studio") > -1 && (
                      <button
                        className="d-inline-block border border-dark rounded-circle text-center text-dark"
                        style={ProductButtonStyles}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Dragonfly AI Studio"
                      >
                        <i className="far fa-desktop" />
                      </button>
                    )}
                    {productAccess.indexOf("extension") > -1 && (
                      <button
                        className="d-inline-block border border-dark rounded-circle text-center text-dark"
                        style={ProductButtonStyles}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Dragonfly AI Extension"
                      >
                        <i className="far fa-browser" />
                      </button>
                    )}
                    {productAccess.indexOf("app") > -1 && (
                      <button
                        className="d-inline-block border border-dark rounded-circle text-center text-dark"
                        style={ProductButtonStyles}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Dragonfly AI App"
                      >
                        <i className="far fa-tablet" />
                      </button>
                    )}
                    {productAccess.indexOf("context") > -1 && (
                      <button
                        className="d-inline-block border border-dark rounded-circle text-center text-dark"
                        style={ProductButtonStyles}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Context"
                      >
                        <i className="far fa-bullseye-pointer" />
                      </button>
                    )}
                  </td>
                ),
              },
              {
                title: "",
                cellRender: (item, key) => (
                  <td className="align-top text-right" key={key}>
                    <div className="dropdown dropdown-sm">
                      <button
                        className="btn btn-link btn-sm"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="far fa-lg fa-ellipsis-v" />
                        </span>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <h6 className="dropdown-header">Actions</h6>
                        <ToggleModal
                          dialogClassName="modal-lg"
                          toggle={(show) => (
                            <span
                              className="dropdown-item pointer"
                              onClick={show}
                            >
                              Manage seats
                            </span>
                          )}
                          content={(hide) => (
                            <ManageSeatsModal
                              hide={hide}
                              subscriptionItem={item}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </td>
                ),
              },
            ]}
          />
        </div>
      </section>
    </div>
  );
}

export default Subscriptions;
