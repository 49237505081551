import { call } from "redux-saga/effects";
import { Toaster } from "../components/Toast";
import { signout } from "../redux/sagas/session";
import axiosInstance from "../utilities/axios-instance";

export function* client(payload) {
  const data = { ...payload };
  try {
    const { data: result, error } = yield call(axiosInstance, data);
    return {
      error,
      result,
    };
  } catch (error) {
    return {
      error,
      result: null,
    };
  }
}

function* ToastMyError(error) {
  let APIErrorMessage = error?.response?.data?.error?.message;
  if (APIErrorMessage) {
    yield call(Toaster, `Message - ${APIErrorMessage}`, "error");
  }
}

function* handleError(error) {
  console.log("error.response.status", error?.response.status);
  if (error?.response?.status === 401) {
    yield call(signout);
  } else {
    yield call(ToastMyError, error);
  }
}

function* httpClient(payload, isLoader = true, authorization = true) {
  if (!navigator.onLine) {
    return {
      error: true,
      result: null,
    };
  }
  const { result, error } = yield call(
    client,
    payload,
    isLoader,
    authorization
  );
  if (error) {
    yield call(handleError, error);
  }

  return { error, result };
}

export default httpClient;
