import React from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import SubHeader from "../components/parts/SubHeader";
import { useGetAccountQuery } from "@/redux/toolkit/accountSlice";
import { useSelector } from "react-redux";

function AccountDetail() {
  const { id: AccID } = useParams();
  const role = useSelector((state) => state.session?.role);
  let { data: { company, appAccountId } = {}, isLoading: accountLoading } =
    useGetAccountQuery({
      appAccountId: AccID,
    });

  if (accountLoading) {
    return (
      <div
        className="loader d-flex flex-column align-items-center justify-content-center my-4"
        style={{ paddingTop: (window.outerHeight / 100) * 30 }}
      >
        <p className="text-muted m-0">
          <i
            className="fal fa-lg fa-spinner fa-fast-spin"
            aria-hidden="true"
          ></i>
        </p>
      </div>
    );
  }

  return (
    <>
      <SubHeader>
        <h1>{company}</h1>
        <p className="text-muted m-0">
          <b>ID</b> {appAccountId}
        </p>
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-3">
              <div
                className="nav flex-column mb-5"
                id="account-tabs"
                role="tablist"
                aria-orientation="vertical"
              >
                <NavLink
                  to={`/accounts/${AccID}/details`}
                  className="nav-link px-0"
                >
                  Details
                </NavLink>
                <NavLink
                  to={`/accounts/${AccID}/users`}
                  className="nav-link px-0"
                >
                  Users
                </NavLink>
                <NavLink
                  to={`/accounts/${AccID}/subscriptions`}
                  className="nav-link px-0"
                >
                  Subscriptions
                </NavLink>
                {role === "super" && (
                  <>
                    <NavLink
                      to={`/accounts/${AccID}/use-cases`}
                      className="nav-link px-0"
                    >
                      Use cases
                    </NavLink>
                    <NavLink
                      to={`/accounts/${AccID}/sso-methods`}
                      className="nav-link px-0"
                    >
                      ID providers
                    </NavLink>
                  </>
                )}
                <NavLink
                  to={`/accounts/${AccID}/delete-account`}
                  className="nav-link px-0"
                >
                  Delete account
                </NavLink>
              </div>
            </div>
            <div className="col-md-9">
              <div className="tab-content" id="account-tabsContent">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountDetail;
