import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RemoveUserModal, ToggleModal, ResetUserMFAModal } from "../components/Modals";
import SubHeader from "../components/parts/SubHeader";
import { Pagination, SearchBox, Table } from "../components/Table";
import methods from "../utilities/methods";
import { useGetAllUsersQuery } from "@/redux/toolkit/userSlice";

function UsersList() {

  const [orderby, setOrderBy] = useState("name");
  const [sortby, setSortby] = useState("asc");
  const [pageIndex, setPageIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [query, setQuery] = useState("");

  const {data: {Users,  MaxPageIndex,  MaxRecordCount}={}, isFetching:isLoading, isSuccess:isFetched } = useGetAllUsersQuery({
    query,
    orderby,
    sortby,
    pageIndex,
    itemsPerPage,
  });

  // Passed through the colMapper to allow role specific renders - currently only implimented
  // in specific use cases check Table.js component for details
  const role = useSelector((state) => state.session.role);

  const SortingFunction = (e, orderby, sortby) => {
    e.preventDefault();
    setOrderBy(orderby);
    setSortby(sortby);
  };

  const onSearch = (e) => {
    setQuery(e.target.value.trimStart());
    setPageIndex(1);
  };
  return (
    <>
      <SubHeader>
        <h1>Users</h1>
        <p className="text-muted m-0">Users can be associated with one or more accounts.</p>
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <SearchBox onSearch={onSearch} query={query} />
          <div className="table-responsive my-4">
            <Table
              orderby={orderby}
              sortby={sortby}
              isLoading={isLoading}
              isFetched={isFetched}
              dataSource={Users}
              colMapper={[
                {
                  title: "Name",
                  keyName: "name",
                  onSorting: SortingFunction,
                  cellRender: ({ firstName, lastName, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {`${firstName} ${lastName}`}
                      <br />
                      <small className="text-muted">
                        <b>ID</b> {id}
                      </small>
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "Email",
                  keyName: "email",
                  onSorting: SortingFunction,
                  cellRender: ({ email }, key) => (
                    <td className="text-nowrap" key={key}>
                      {email}
                    </td>
                  ),
                  width: "300px",
                },
                {
                  title: "Accounts",
                  keyName: "subscriptions",
                  cellRender: ({ accounts }, key) => (
                    <td className="col-5" key={key}>
                      <small>
                        {accounts.map(({ id, company }) => (
                          <span key={id}>
                            {company} (<a href={`/accounts/${id}/details`}>view</a>)<br />
                          </span>
                        ))}
                      </small>
                    </td>
                  ),
                },
                {
                  title: "Status",
                  keyName: "state",
                  cellRender: (item, key) => (
                    <td className='col-1' key={key}>
                      {item.state ==='active' ? methods.ucFirst(item.state): "MFA reset" }
                    </td>
                  ),
                },
                {
                  title: "Type",
                  keyName: "type",
                  role: role,
                  cellRender: ({ type }, key) => (
                    <td className="col-1" key={key}>
                      {type !== "" ? type : null}
                    </td>
                  ),
                },
                {
                  title: "Created",
                  keyName: "createdat",
                  onSorting: SortingFunction,
                  cellRender: ({ createdAt }, key) => (
                    <td className="col-1" key={key}>
                      {methods.formatDate(createdAt)}
                    </td>
                  ),
                },
                {
                  title: "",
                  cellRender: (user, key) => (
                    <td className="align-middle text-right" key={key}>
                      <div className="dropdown dropdown-sm">
                        <button
                          className="btn btn-link btn-sm"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="far fa-lg fa-ellipsis-v" />
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <h6 className="dropdown-header">Actions</h6>
                          {user.state === "active" &&
                          <ToggleModal
                            toggle={(show) => (
                              <span
                                className='dropdown-item pointer'
                                onClick={show}
                              >
															Reset MFA
														</span>
                            )}
                            content={(hide) => (
                              <ResetUserMFAModal
                                hide={(deleteSuccess = false) => {
                                  hide();
                                }}
                                user={user}
                                userSuperAdmin={true}
                              />
                            )}
                          />
                          }
                          <div className="dropdown-divider"></div>
                          <ToggleModal
                            toggle={(show) => (
                              <span className="dropdown-item pointer text-danger" onClick={show}>
                                Remove
                              </span>
                            )}
                            content={(hide) => (
                              <RemoveUserModal
                                hide={(deleteSuccess = false) => {
                                  hide();
                                }}
                                user={user}
                                method="removeUser"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </td>
                  ),
                },
              ]}
            />
          </div>
          <nav className="navbar navbar-expand-lg navbar-light border-top">
            {Users?.length > 0 && (
              <Pagination
                key={`${itemsPerPage}-${orderby}-${sortby}`}
                maxPageIndex={MaxPageIndex}
                pageIndex={pageIndex}
                totalRecords={MaxRecordCount}
                pageLimit={itemsPerPage}
                pageNeighbours={2}
                onPageChanged={({ currentPage }) => setPageIndex(currentPage)}
                onLimitChange={(itemsPerPage) => {
                  setPageIndex(1);
                  setItemsPerPage(itemsPerPage);
                }}
              />
            )}
          </nav>
        </div>
      </section>
    </>
  );
}

export default UsersList;
