import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateSession } from "../redux/actions/session-action-types";

function AuthIFrame(props) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const handler = (event) => {
      if (event.data.type === "auth") {
        let { user: { id, email, firstName, lastName, createdAt } } = event.data.session;
        let session = event.data.session;
        window.analytics.identify(id, { email, firstName, lastName, name: `${firstName} ${lastName}`, createdAt, id });
        dispatch(updateSession(event.data.session));
        let segmentData = {
          groupType: "account",
          id: session.account && session.account.id ? session.account.id : "",
          name: session.account && session.account.company ? session.account.company : "",
          type: session.account && session.account.type ? session.account.type : "",
          tier: session.account && session.account.tier ? session.account.tier : "",
          createdAt: session.account && session.account.createdAt ? session.account.createdAt : "",
        };
        if(session.account && session.account.organisation && session.account.organisation.id) {
          segmentData.parentGroupId = session.account.organisation.id;
        }
        window.analytics.group(session.account && session.account.id ? session.account.id : "", {
          ...segmentData
          },
          {
            groupId: session.account && session.account.id ? session.account.id : "",
            userId: id ? id : ""
          });

        if(session.account && session.account.organisation && session.account.organisation.id) {
          window.analytics.group(session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "", {
            groupType: "organization",
            id: session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "",
            name: session.account && session.account.organisation && session.account.organisation.name ? session.account.organisation.name : "",
            createdAt: session.account && session.account.organisation && session.account.organisation.createdAt ? session.account.organisation.createdAt : "",
            updatedAt: session.account && session.account.organisation && session.account.organisation.updatedAt ? session.account.organisation.updatedAt : "",
          });
        }
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [dispatch]);

  return (
    <iframe
      style={{
        display: "block",
        border: "none",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#fff",
      }}
      title="authenticationIframe"
      src={process.env.REACT_APP_AUTHENTICATION_IFRAME_URL + props.path}
      allow="clipboard-read; clipboard-write"
    />
  );
}

export default AuthIFrame;
