import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../form-fields";
import { useCloneBenchmarkMutation } from "@/redux/toolkit/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { Toaster } from '@/components/Toast';

function CloneBenchmark({ hide, data: { accountId } }) {
  const [ cloneBenchmark ] = useCloneBenchmarkMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const sourceAccountId = `${process.env.REACT_APP_SOURCE_ACCOUNT_ID}`;

  useEffect(() => {
    setIsSubmitting(false);
    if(isSuccess){
      hide(true);
    }
  }, [isSuccess, hide]);
  const onSubmit = async(value) => {
    setIsSubmitting(true);
    const ids = value.id.split(",");
    for(const id of ids) {
      if(id.trim().length) {
        try {
          await cloneBenchmark({
            destinationAccountId: accountId,
            sourceBenchmarkId: id.trim(),
            sourceAccountId: sourceAccountId
          }).unwrap();
          Toaster('Benchmark/s cloned successfully.');
        } catch (error) {
          Toaster(error.data.error.message, 'error');
        }
      }
    }
    setIsSuccess(true);
  };

  return (
    <Formik
      initialValues={{ id: "" }}
      validationSchema={Yup.object({
        id: Yup.string()
          .required("Id is required."),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Apply Benchmark
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1" />
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-4">
                  <div className=" col-12">
                    <div className="form-label-group">
                      <TextInput label="Id" name="id" type="text" placeholder="Id" className="form-control" />
                    </div>
                    <div role="alert" className="fade fs-sm p-3 pl-5 position-relative mt-2 alert alert-info show">
                      <FontAwesomeIcon size="xl" className="position-absolute canvas-visualization m-3" icon={faInfoCircle} />
                      <p className="readable-md ms-2">
                        Applying a Benchmark/s from the 'Benchmark Account Dragonfly' account will take a snapshot of the pack and
                        create a new one in your selected account. <br />
                        You can copy one by simply pasting the id of the pack or multiple with a comma separated list e.g.{" "}
                      </p>
                      <p className="mb-0 text-danger">
                        256025cc-e54e-4568-9230-71ce2949dd30,<br />
                        26d1c49f-7397-4aa2-9da7-d026451cea34,<br />
                        36319de4-2e3c-4171-87ec-36492150492a,<br />
                        3df64f1c-b0d2-446d-93b3-09a924552463,<br />
                        69c4e6dc-e0aa-4409-aad8-84334cacdf19
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Apply"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Applying"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CloneBenchmark;
