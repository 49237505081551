import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

const ClearCache = (props) => {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
  const {isPopUp, setIsPopUp} = props;

  useEffect(() => {
    const timestamp= new Date().getTime();
    fetch(`/meta.json?t=${timestamp}`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          setIsLatestBuildDate(true);
          setIsPopUp(true);
          // refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(false);
        }
      });
  }, [setIsPopUp]);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      {isLatestBuildDate && isPopUp ? <div className="alert alert-dark d-flex justify-content-between;m w-auto align-items-center fixed-bottom start-40 justify-content-center mx-auto mb-3" role="alert" style={{zIndex: 1000}}>
        <p className="m-0 p-0">Admin has just been updated, click "Refresh" to start using the latest release!
        </p>
        <button className="btn btn-primary mx-3" onClick={() => refreshCacheAndReload()}>Refresh</button>
        <button type="button" aria-label="Close" data-bs-dismiss="alert"  className="h4 btn-close btn-close-black bg-transparent border-0 m-0" onClick={() => setIsPopUp(false)}>    <span aria-hidden="true">&times;</span>
        </button>
      </div> : null}
    </React.Fragment>
  );
}



export default ClearCache;