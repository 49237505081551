import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";

import createSagaMiddleware from "redux-saga";
import { createRouterMiddleware, createRouterReducer } from "@lagunovsky/redux-react-router";
import { history } from "../../config/history";

/* Reducers */
import accounts from "../reducers/accounts";
import users from "../reducers/users";
import session from "../reducers/session";


// base API slice
import { api } from "./apiSlice";

// All Sagas
import sagas from "../sagas/index";

// combineReducer allows you to call seperate reducers for each state
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  accounts: accounts,
  users: users,
  session: session,
  router: createRouterReducer(history),
});

const persistConfig = {
  key: "primary-2",
  version: 1,
  storage,
  blacklist: ["app", "api"],
};

/* Middleware */
const routerMiddleware = createRouterMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware, sagaMiddleware, api.middleware];

/* Reducer */
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // Can create a root reducer separately and pass that in
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      // Customize the built-in serializability dev check
      /* serializableCheck: {
        ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ],
      }, */
      serializableCheck: false,
    }).concat(middlewares);

    // Conditionally add another middleware in dev
    if (process.env.NODE_ENV !== "production") {
      middleware.push(logger);
    }

    return middleware;
  },
  // Turn off devtools in prod, or pass options in dev
  devTools: process.env.NODE_ENV !== "production",
});

// Run Sagas
sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
