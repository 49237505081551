import {
  GET_ACCOUNT_SUBSCRIPTIONS_FAILURE,
  GET_ACCOUNT_SUBSCRIPTIONS_REQUESTED,
  GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS,
  GET_ACCOUNT_SUBSCRIPTION_FAILURE,
  GET_ACCOUNT_SUBSCRIPTION_REQUESTED,
  GET_ACCOUNT_SUBSCRIPTION_SUCCESS,
  GET_ACCOUNT_SUBSCRIPTION_USERS_FAILURE,
  GET_ACCOUNT_SUBSCRIPTION_USERS_REQUESTED,
  GET_ACCOUNT_SUBSCRIPTION_USERS_SUCCESS,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_USERS_FAILURE,
  GET_ACCOUNT_USERS_REQUESTED,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_ALL_ACCOUNTS_FAILURE,
  GET_ALL_ACCOUNTS_REQUESTED,
  GET_ALL_ACCOUNTS_SUCCESS,
  SEARCH_ACCOUNT_USERS_FAILURE,
  SEARCH_ACCOUNT_USERS_REQUESTED,
  SEARCH_ACCOUNT_USERS_SUCCESS,
  SEARCH_ALL_ACCOUNTS_FAILURE,
  SEARCH_ALL_ACCOUNTS_REQUESTED,
  SEARCH_ALL_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNT_REQUESTED,
  UPDATE_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_SUBSCRIPTION_USER_SUCCESS,
  DELETE_ACCOUNT_SUBSCRIPTION_USER_SUCCESS,
  GET_ORGANISATIONS_FAILURE,
  GET_ORGANISATIONS_REQUESTED,
  GET_ORGANISATIONS_SUCCESS,
  UPDATE_ACCOUNT_ORGANISATION_FAILURE,
  UPDATE_ACCOUNT_ORGANISATION_REQUESTED,
  UPDATE_ACCOUNT_ORGANISATION_SUCCESS,
} from "../actions/accounts-action-types";
import { SIGNOUT_SUCCESS } from "../actions/session-action-types";
import { REHYDRATE } from "redux-persist";

const initialState = {
  allAccounts: {},
  getAccount: {},
  accountUsers: {},
  accountSubscriptions: {},
  accountSubscription: {},
  accountSubscriptionUsers: {},
  organisations:{},
  accountOrganisation:{},
};

export default function accounts(state = initialState, { payload, type }) {
  switch (type) {
    case GET_ALL_ACCOUNTS_REQUESTED:
    case SEARCH_ALL_ACCOUNTS_REQUESTED:
      return {
        ...state,
        allAccounts: { isLoading: true, isFetched: false, data: null },
      };

    case GET_ALL_ACCOUNTS_FAILURE:
    case SEARCH_ALL_ACCOUNTS_FAILURE:
      return {
        ...state,
        allAccounts: { isLoading: false, isFetched: true, data: null },
      };

    case GET_ALL_ACCOUNTS_SUCCESS:
    case SEARCH_ALL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        allAccounts: { isLoading: false, isFetched: true, data: payload },
      };

    case GET_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        getAccount: {
          loading: false,
          data: payload,
        },
      };

    case UPDATE_ACCOUNT_REQUESTED: {
      return {
        ...state,
        getAccount: {
          ...state.getAccount,
          loading: true,
        },
      };
    }

    case SEARCH_ACCOUNT_USERS_REQUESTED:
    case GET_ACCOUNT_USERS_REQUESTED: {
      return {
        ...state,
        accountUsers: { isLoading: true, isFetched: false, data: null },
      };
    }

    case SEARCH_ACCOUNT_USERS_SUCCESS:
    case GET_ACCOUNT_USERS_SUCCESS: {
      return {
        ...state,
        accountUsers: { isLoading: false, isFetched: true, data: payload },
      };
    }

    case SEARCH_ACCOUNT_USERS_FAILURE:
    case GET_ACCOUNT_USERS_FAILURE: {
      return {
        ...state,
        accountUsers: { isLoading: false, isFetched: true, data: null },
      };
    }

    case GET_ACCOUNT_SUBSCRIPTIONS_REQUESTED:
      return {
        ...state,
        accountSubscriptions: { isLoading: true, isFetched: false, data: null },
      };
    case GET_ACCOUNT_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        accountSubscriptions: { isLoading: false, isFetched: true, data: null },
      };
    case GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        accountSubscriptions: {
          isLoading: false,
          isFetched: true,
          data: payload,
        },
      };

    case GET_ACCOUNT_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        accountSubscription: { isLoading: true, isFetched: false, data: null },
      };
    case GET_ACCOUNT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        accountSubscription: { isLoading: false, isFetched: true, data: null },
      };
    case GET_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        accountSubscription: {
          isLoading: false,
          isFetched: true,
          data: payload,
        },
      };

    case GET_ACCOUNT_SUBSCRIPTION_USERS_REQUESTED:
      return {
        ...state,
        accountSubscriptionUsers: {
          isLoading: true,
          isFetched: false,
          data: null,
        },
      };
    case GET_ACCOUNT_SUBSCRIPTION_USERS_FAILURE:
      return {
        ...state,
        accountSubscriptionUsers: {
          isLoading: false,
          isFetched: true,
          data: null,
        },
      };
    case GET_ACCOUNT_SUBSCRIPTION_USERS_SUCCESS:
      return {
        ...state,
        accountSubscriptionUsers: {
          isLoading: false,
          isFetched: true,
          data: payload,
        },
      };

    case ADD_ACCOUNT_SUBSCRIPTION_USER_SUCCESS:
      return {
        ...state,
        accountSubscription: {
          ...state.accountSubscription,
          data: {
            ...state.accountSubscription.data,
            subscription: {
              ...state.accountSubscription.data.subscription,
              seatRemaining:
                state.accountSubscription.data.subscription.seatRemaining - 1,
            },
          },
        },
        accountSubscriptions: {
          ...state.accountSubscriptions,
          data: {
            ...state.accountSubscriptions.data,
            subscriptions: state.accountSubscriptions.data.subscriptions.map(
              (x) =>
                x.id === payload.appSubscriptionId
                  ? { ...x, seatRemaining: x.seatRemaining - 1 }
                  : x
            ),
          },
        },
      };

    case DELETE_ACCOUNT_SUBSCRIPTION_USER_SUCCESS:
      return {
        ...state,
        accountSubscription: {
          ...state.accountSubscription,
          data: {
            ...state.accountSubscription.data,
            subscription: {
              ...state.accountSubscription.data.subscription,
              seatRemaining:
                state.accountSubscription.data.subscription.seatRemaining + 1,
            },
          },
        },
        accountSubscriptions: {
          ...state.accountSubscriptions,
          data: {
            ...state.accountSubscriptions.data,
            subscriptions: state.accountSubscriptions.data.subscriptions.map(
              (x) =>
                x.id === payload.appSubscriptionId
                  ? { ...x, seatRemaining: x.seatRemaining + 1 }
                  : x
            ),
          },
        },
      };

    case GET_ORGANISATIONS_REQUESTED:
      return {
        ...state,
        organisations: {
          isLoading: true,
          isFetched: false,
          data: null,
        },
      };
    case GET_ORGANISATIONS_FAILURE:
      return {
        ...state,
        organisations: {
          isLoading: false,
          isFetched: true,
          data: null,
        },
      };
    case GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        organisations: {
          isLoading: false,
          isFetched: true,
          data: payload,
        },
      };

    case UPDATE_ACCOUNT_ORGANISATION_REQUESTED:
      return {
        ...state,
        accountOrganisation: {
          isLoading: true,
          isFetched: false,
          data: null,
        },
      };
    case UPDATE_ACCOUNT_ORGANISATION_FAILURE:
      return {
        ...state,
        accountOrganisation: {
          isLoading: false,
          isFetched: true,
          data: null,
        },
      };
    case UPDATE_ACCOUNT_ORGANISATION_SUCCESS:
      return {
        ...state,
        accountOrganisation: {
          isLoading: false,
          isFetched: true,
          data: payload,
        },
      };

    case SIGNOUT_SUCCESS:
      return { ...initialState };

    case REHYDRATE: {
      let data = { ...initialState };
      if (payload?.accounts) {
        data = payload.accounts;
      }
      return data;
    }

    default:
      return state;
  }
}
