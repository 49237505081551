import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/AuthLayout";
import PublicLayout from "../layouts/Public";
import AccountDetail from "../containers/AccountDetail";
import AccountsList from "../containers/AccountList";
import AuthIFrame from "../containers/AuthIFrame";
import NotFound from "../containers/NotFound";
import Profile from "../containers/Profile";
import UsersList from "../containers/UsersList";
import Organisations from "../containers/Organisations";
import UseCases from "../containers/UseCases";
import {
  BasicInfo,
  ChangeEmail,
  ResetPassword,
  DeleteAccount,
} from "../components/Profile";
import {
  DeleteAccount as DeleteUserAccount,
  Details,
  Subscriptions,
  Users,
  Settings,
  IDProviders,
} from "../components/AccountDetail";

function Navigation() {
  const role = useSelector((state) => state.session?.role);
  const accountId = useSelector((state) => state.session?.account?.id);

  const mapRoleToLandingPage = (role) => {
    const PublicLandingPage = "/";
    const UserLandingPage = "/profile/basic-info";
    const AdminLandingPage = `/accounts/${accountId}/details`;
    const SuperAdminLandingPage = "/accounts";

    switch (role) {
      case "super":
        return SuperAdminLandingPage;

      case "admin":
        return AdminLandingPage;

      case "user":
        return UserLandingPage;

      case null:
        return PublicLandingPage;

      default:
        break;
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          role !== null && accountId !== undefined ? (
            <Navigate replace to="/profile/basic-info" />
          ) : (
            <Navigate replace to="/signin" />
          )
        }
      />

      <Route
        element={
          <AuthLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
          />
        }
      >
        <Route path="/signin" element={<AuthIFrame path="/signin" />} />
        <Route path="/signout" element={<AuthIFrame path="/signout" />} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route
          path="/select/account"
          element={<AuthIFrame path="/select/account" />}
        />
      </Route>

      <Route
        path="/accounts"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={AccountsList}
          />
        }
      />
      <Route
        path="/accounts/:id"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={AccountDetail}
          />
        }
      >
        <Route path="details" element={<Details />} />
        <Route path="users" element={<Users />} />
        <Route path="subscriptions" element={<Subscriptions />} />
        <Route path="use-cases" element={<Settings />} />
        <Route path="delete-account" element={<DeleteUserAccount />} />
        <Route path="sso-methods" element={<IDProviders />} />
      </Route>
      <Route
        path="/users"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={UsersList}
          />
        }
      />
      <Route
        path="/profile"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={Profile}
          />
        }
      >
        <Route path="/profile/basic-info" element={<BasicInfo />} />
        <Route path="/profile/change-email" element={<ChangeEmail />} />
        <Route path="/profile/reset-password" element={<ResetPassword />} />
        <Route path="/profile/delete-account" element={<DeleteAccount />} />
      </Route>
      <Route
        path="/organisations"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={Organisations}
          />
        }
      />
      <Route
        path="/usecases"
        element={
          <DashboardLayout
            mapRoleToLandingPage={mapRoleToLandingPage}
            role={role}
            accountId={accountId}
            Component={UseCases}
          />
        }
      />
      <Route path="*" element={<PublicLayout Component={NotFound} />} />
    </Routes>
  );
}

export default Navigation;
