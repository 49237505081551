import { api } from "@/redux/toolkit/apiSlice";
import {UpdateUserSuccess} from '@/redux/actions/session-action-types';
import { Toaster } from '@/components/Toast';

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ query, orderby, sortby, pageIndex, itemsPerPage }) => {
        if(query.length) {
          return  `/manage/users/search?query=${query}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        } else {
          return `/manage/users?orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        }
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster('Failed to fetch all users', 'error');
        }
      },
      transformResponse: (response) => {
      return {
        Users: response.payload.users,
        MaxPageIndex: response.payload.maxPageIndex,
        MaxRecordCount: response.payload.maxRecordCount,
      };
    },
      providesTags: ["Users"],
    }),

    removeUser: builder.mutation({
      query: ({ accountUser, appUserId, appAccountId }) => ({
        url: accountUser ? `/manage/accounts/${appAccountId}/users/${appUserId}`:  `/manage/users/${appUserId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users", "AccountUser"],
    }),
    updateUser: builder.mutation({
      query: ({ appUserId, data }) => ({
        url: `/manage/users/${appUserId}`,
        method: "PUT",
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(UpdateUserSuccess(data.payload.user));
          Toaster("User successfully updated.");
        } catch (err) {
          Toaster("User update failed.", "error");
        }
      },
      invalidatesTags: ["Users"],
    }),
    updateUserPassword: builder.mutation({
      query: ({ appUserId, data }) => ({
        url: `/manage/users/${appUserId}/password`,
        method: "PUT",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("User password successfully updated.");
        } catch (err) {
          Toaster("User password update failed.", "error");
        }
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useRemoveUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation
} = extendedApiSlice;