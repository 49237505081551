import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import SubHeader from "../components/parts/SubHeader";
import { useSelector } from "react-redux";

function Profile() {
  const firstName = useSelector((state) => state.session.user.firstName);
  const lastName = useSelector((state) => state.session.user.lastName);
  const id = useSelector((state) => state.session.user.id);

  return (
    <>
      <SubHeader>
        <h1>{`${firstName} ${lastName}`}</h1>
        <p className="text-muted m-0">
          <b>ID</b> {id}
        </p>
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-3">
              <div className="nav flex-column mb-5" id="account-tabs" role="tablist" aria-orientation="vertical">
                <NavLink to="/profile/basic-info" className="nav-link px-0">
                  Basic info
                </NavLink>
                <NavLink to="/profile/change-email" className="nav-link px-0">
                  Change email
                </NavLink>
                <NavLink to="/profile/reset-password" className="nav-link px-0">
                  Change password
                </NavLink>
                <NavLink to="/profile/delete-account" className="nav-link px-0">
                  Delete account
                </NavLink>
              </div>
            </div>
            <div className="col-md-9">
              <div className="tab-content" id="account-tabsContent">
                <Outlet/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
