import { useField } from "formik";
import React from "react";

const modifyClasses = (className, meta) => {
  if (meta.touched) {
    let isInValid = meta.error;
    if (isInValid && !className.includes("is-invalid")) className = className + " is-invalid";
    if (!isInValid && !className.includes("is-valid")) className = className + " is-valid";
  }
  return className;
};

export const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label>
        <input type="checkbox" {...field} {...props} /> {children}
      </label>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </>
  );
};

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  props.className = modifyClasses(props.className, meta);

  return (
    <>
      <input {...field} {...props} />

      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </>
  );
};

export const Button = ({
  label,
  dirty,
  errors,
  isValidating,
  isSubmitting,
  className = "btn btn-outline-primary",
  processingMsg = "Saving",
  ...props
}) => {
  return (
    <button type="submit" className={className} disabled={!dirty || Object.keys(errors).length || isValidating || isSubmitting} {...props}>
      {isValidating || isSubmitting ? (
        <>
          <i className="fal fa-spinner fa-spin mr-1" aria-hidden="true"></i>
          {` ${processingMsg}`}
        </>
      ) : (
          label
        )}
    </button>
  );
};
