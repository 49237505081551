const Method = {
  formatDate: (date) => {
    date = new Date(date);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return day + "/" + month + "/" + year;
  },

  ucFirst: (str, ln) => {
    if (str) {
      let limit = str.length;
      if (limit > 0) {
        return (
          str
            .toLowerCase()
            .split(" ")
            .map(function (word) {
              if (word) {
                return word[0].toUpperCase() + word.substr(1);
              }
              return word;
            })
            .join(" ")
            .substring(0, ln) + (limit > ln ? "..." : "")
        );
      }
    }
  },
  ucFirstLetter: (str, ln) => {
    if (str) {
      let limit = str.length;
      if (limit > 0) {
        return (
          str.charAt(0).toUpperCase() +
          str.slice(1).substring(0, ln) +
          (limit > ln ? "..." : "")
        );
      }
    }
  },
};

export default Method;
