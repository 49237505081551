import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, TextInput } from "../../components/form-fields";
import { useUpdateUserMutation } from "@/redux/toolkit/userSlice";

const ChangeEmailSchema = Yup.object({
  email: Yup.string().email("Invalid email address.").required("Email address is a required field."),
});

function ChangeEmail() {

  const [ updateUser, { isLoading: updatingUser } ] = useUpdateUserMutation();
  const firstName = useSelector((state) => state.session.user.firstName);
  const lastName = useSelector((state) => state.session.user.lastName);
  const initEmail = useSelector((state) => state.session.user.email);
  const appUserId = useSelector((state) => state.session.user.id);
  const session = useSelector((state) => state.session);


  useEffect(() => {
    let segmentData = {
      title: `User Email | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
    if(session.account && session.account.organisation && session.account.organisation.id){
      segmentData.organizationId = session.account.organisation.id;
    }
    window.analytics.page("User Email", {
      ...segmentData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    })
  }, [session.account, session.subscription, session.user]);

  const onSubmit = (values) => {
    updateUser({appUserId, data: {...values, firstName, lastName}});
    let { id, createdAt } = session.user;
    window.analytics.identify(id, { email: values.email, firstName, lastName, name: `${firstName} ${lastName}`, createdAt, id });
  }

  return (
    <div className="tab-pane fade show active" id="changeemail" role="tabpanel" aria-labelledby="changeemail-tab">
      <section className="mb-5">
        <p className="lead">Change email address</p>
        <div className="card">
          <div className="card-body">
            <Formik
              key={initEmail}
              initialValues={{
                email: initEmail ?? "",
              }}
              validationSchema={ChangeEmailSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, errors, isValidating }) => (
                <Form className="form needs-validation">
                  <div className="form-group mb-3">
                    <div className="form-label-group">
                      <TextInput label="Email address" name="email" type="email" placeholder="Email address" className="form-control" />
                    </div>
                  </div>
                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="First group">
                      <Button label={"Save"} dirty={dirty} errors={errors} isValidating={isValidating} isSubmitting={updatingUser} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangeEmail;
