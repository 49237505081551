import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { signout } from "@/redux/actions/session-action-types"

const baseQuery = retry(fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "DF-Version": process.env.REACT_APP_DF_VERSIONS,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  credentials: "include",},
), {
  maxRetries: 5,
});
const baseQueryWithAuthCheck = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(signout());
  }
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithAuthCheck,
  tagTypes: ["Users", "Accounts", "AccountUser", "AccountContact", "AccountSubscription", "SubscriptionUser", "Organisation"],
  endpoints: () => ({}),
});
