import React from "react";

function Public({ Component }) {

  document.body.className = "d-flex align-items-center justify-content-center";
  return (
    <main role="main" className="container">
      <Component />
    </main>
  );
}

export default Public;
