import { SIGNOUT_SUCCESS } from "../actions/session-action-types";
import {
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_REQUESTED,
  GET_ALL_USERS_SUCCESS,
  SEARCH_ALL_USERS_FAILURE,
  SEARCH_ALL_USERS_REQUESTED,
  SEARCH_ALL_USERS_SUCCESS,
} from "../actions/users-actions-types";
import { REHYDRATE } from "redux-persist";

const initialState = {
  allUsers: {},
};
export default function users(state = initialState, { payload, type }) {
  switch (type) {
    case GET_ALL_USERS_REQUESTED:
    case SEARCH_ALL_USERS_REQUESTED:
      return { ...state, allUsers: { isLoading: true, isFetched: false, data: null } };

    case GET_ALL_USERS_FAILURE:
    case SEARCH_ALL_USERS_FAILURE:
      return { ...state, allUsers: { isLoading: false, isFetched: true, data: null } };

    case GET_ALL_USERS_SUCCESS:
    case SEARCH_ALL_USERS_SUCCESS:
      return { ...state, allUsers: { isLoading: false, isFetched: true, data: payload } };

    case SIGNOUT_SUCCESS:
      return { ...initialState };

    case REHYDRATE: {
      let data = { ...initialState };
      if (payload?.users) {
        data = payload.users;
      }
      return data;
    }

    default:
      return state;
  }
}
