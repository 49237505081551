import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function DeleteAccount() {
  const session = useSelector((state) => state.session);

  useEffect(() => {
  	let segmentData = {
      title: `User Deletion | Dragonfly AI Admin`,
      userId: session.user && session.user.id ? session.user.id : "",
      accountId : session.account && session.account.id ? session.account.id : "",
      accountName : session.account && session.account.company ? session.account.company : "",
      accountType : session.account && session.account.type ? session.account.type : "",
      subscriptionId : session.subscription && session.subscription.id ? session.subscription.id : "",
      subscriptionChargebeeId : session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
      subscriptionStatus : session.subscription && session.subscription.status ? session.subscription.status : "",
      planId : session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
      planChargebeeId : session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
    };
  	if(session.account && session.account.organisation && session.account.organisation.id){
  		segmentData.organizationId = session.account.organisation.id;
		}
    window.analytics.page("User Deletion", {
			...segmentData
		},
		{
			groupId: session.account && session.account.id ? session.account.id : ""
		})
  }, [session.user, session.account, session.subscription]);

	return (
		<div
			className="tab-pane fade show active"
			id="deleteaccount"
			role="tabpanel"
			aria-labelledby="deleteaccount-tab"
		>
			<section className="mb-5">
				<p className="lead">Delete account</p>
				<p>
					To delete your account please contact your organization's
					administrator.
				</p>
				<p>
					<b>Need assistance?</b> Contact{" "}
					<a href="maito:support@dragonflyai.co">support@dragonflyai.co</a>
				</p>
			</section>
		</div>
	);
}

export default DeleteAccount;
