import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Toaster = (message, type = "success") =>
  toast(
    ({ closeToast }) => (
      <div className="toast show mb-1" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
        <div className="toast-header text-dark pr-1">
          <img src="/images/dragonflyai-ident.svg" className="rounded mr-2" alt="..." width="16" height="16" />
          <strong className="mr-auto">Dragonfly AI</strong>
          <small className="text-muted">just now</small>
          <button type="button" className="btn btn-sm btn-link ml-2 text-muted" data-dismiss="toast" aria-label="Close" onClick={closeToast}>
            <span aria-hidden="true">
              <i className="fa fa-times"></i>
            </span>
          </button>
        </div>
        <div className="toast-body">
          {Boolean(type === "success") && <i className="far fa-lg fa-check-circle text-success mr-1"></i>}
          {Boolean(type === "error") && <i className="far fa-lg fa-exclamation-circle text-danger mr-1"></i>}
          {message}
        </div>
      </div>
    ),
    {
      autoClose: 5000,
      hideProgressBar: true,
      pauseOnHover: true,
      closeButton: false,
      position: "bottom-left",
    }
  );

export { Toaster };
