import React from "react";
import { SortBox, TableLoader } from "../Table";

function Table({ dataSource, colMapper, orderby, sortby, isLoading, isFetched }) {
  return (
    <table className="table">
      <thead className="bg-light">
        <tr>
          {colMapper.map((col, i) => {
            switch (true) {
              case col.onSorting && typeof col.onSorting === "function":
                return (
                  <SortBox
                    orderby={orderby}
                    sortby={sortby}
                    keyName={col.keyName}
                    label={col.title}
                    onSorting={col.onSorting}
                    key={`heading-col-${i}`}
                    style={col.width ? { width: col.width } : {}}
                  />
                );
              case !col.title:
                return <th className="col-actions p-0" scope="col" key={`heading-col-${i}`} style={col.width ? { width: col.width } : {}}></th>;
              case col.title && !col.onSorting && Object.hasOwn(col, 'role'):
                return (
                  col.role === "super" ? <th className="p-0" scope="col" key={`heading-col-${i}`} style={col.width ? { width: col.width } : {}}>
                    <button className="btn btn-light btn-block text-left rounded-0 clearfix" type="button" disabled>
                      {col.title}
                    </button>
                  </th> : null
                );
              case col.title && !col.onSorting:
                return (
                  <th className="p-0" scope="col" key={`heading-col-${i}`} style={col.width ? { width: col.width } : {}}>
                    <button className="btn btn-light btn-block text-left rounded-0 clearfix" type="button" disabled>
                      {col.title}
                    </button>
                  </th>
                );
              default:
                return null;
            }
          })}
        </tr>
      </thead>
      <tbody>
        {Boolean(isLoading) && <TableLoader fetching={isLoading} colSpan={colMapper.length} />}
        {Boolean(!isLoading && isFetched && dataSource?.length) &&
          dataSource?.map((item, i) => (
            <tr key={`data-row-${i}`}>
              {colMapper.map((col, j) => {
                switch (true) {
                  case col.cellRender && typeof col.cellRender === "function" && Object.hasOwn(col, 'role'):
                    return col.role === "super" ? col.cellRender(item, `data-col-${i}-${j}`) : null; // item, key
  
                  case col.cellRender && typeof col.cellRender === "function":
                    return col.cellRender(item, `data-col-${i}-${j}`); // item, key

                  case Boolean(!col.cellRender && col.keyName && item[col.keyName]):
                    return (
                      <td className="text-nowrap" key={`data-col-${i}-${j}`}>
                        {item[col.keyName]}
                      </td>
                    );

                  default:
                    return null;
                }
              })}
            </tr>
          ))}
        {Boolean(!isLoading && isFetched && (dataSource?.length === 0 || !dataSource)) && (
          <tr>
            <td colSpan={colMapper.length}>No Records found.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default Table;
