import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';
import { Toaster } from '../../components/Toast';
import httpClient from '../../config/http-client';
import {
	AddAccountContactFailure,
	AddAccountContactRequested,
	AddAccountContactSuccess,
	AddAccountSubscriptionUserFailure,
	AddAccountSubscriptionUserRequested,
	AddAccountSubscriptionUserSuccess,
	ADD_ACCOUNT_CONTACT,
	ADD_ACCOUNT_SUBSCRIPTION_USER,
	DeleteAccountSubscriptionUserFailure,
	DeleteAccountSubscriptionUserRequested,
	DeleteAccountSubscriptionUserSuccess,
	DELETE_ACCOUNT_SUBSCRIPTION_USER,
	GetAccountFailure,
	GetAccountRequested,
	GetAccountSubscriptionFailure,
	GetAccountSubscriptionRequested,
	GetAccountSubscriptionsFailure,
	GetAccountSubscriptionsRequested,
	GetAccountSubscriptionsSuccess,
	GetAccountSubscriptionSuccess,
	GetAccountSubscriptionUsersFailure,
	GetAccountSubscriptionUsersRequested,
	GetAccountSubscriptionUsersSuccess,
	GetAccountSuccess,
	GetAccountUsersFailure,
	GetAccountUsersRequested,
	GetAccountUsersSuccess,
	GetAllAccountsFailure,
	GetAllAccountsRequested,
	GetAllAccountsSuccess,
	GET_ACCOUNT,
	GET_ACCOUNT_SUBSCRIPTION,
	GET_ACCOUNT_SUBSCRIPTIONS,
	GET_ACCOUNT_SUBSCRIPTION_USERS,
	GET_ACCOUNT_USERS,
	GET_ALL_ACCOUNTS,
	RemoveAccountContactFailure,
	RemoveAccountContactRequested,
	RemoveAccountContactSuccess,
	RemoveAccountFailure,
	RemoveAccountRequested,
	RemoveAccountSuccess,
	RemoveAccountUserFailure,
	RemoveAccountUserRequested,
	RemoveAccountUserSuccess,
	REMOVE_ACCOUNT,
	REMOVE_ACCOUNT_CONTACT,
	REMOVE_ACCOUNT_USER,
	SearchAccountUsersFailure,
	SearchAccountUsersRequested,
	SearchAccountUsersSuccess,
	SearchAllAccountsFailure,
	SearchAllAccountsRequested,
	SearchAllAccountsSuccess,
	SEARCH_ACCOUNT_USERS,
	SEARCH_ALL_ACCOUNTS,
	UpdateAccountContactFailure,
	UpdateAccountContactRequested,
	UpdateAccountContactSuccess,
	UpdateAccountFailure,
	UpdateAccountRequested,
	UpdateAccountSuccess,
	UpdateAccountUserFailure,
	UpdateAccountUserRequested,
	UpdateAccountUserSuccess,
	UPDATE_ACCOUNT,
	UPDATE_ACCOUNT_CONTACT,
	UPDATE_ACCOUNT_USER,
	AddAccountUserFailure,
	AddAccountUserSuccess,
	AddAccountUserRequested,
	ADD_ACCOUNT_USER,
	ResetAccountUserMFAFailure,
	ResetAccountUserMFASuccess,
	ResetAccountUserMFARequested,
	RESET_ACCOUNT_USER_MFA,
	ResetUserMFAFailure,
	ResetUserMFASuccess,
	ResetUserMFARequested,
  RESET_USER_MFA,
	GET_ORGANISATIONS,
	GetOrganisationsFailure,
	GetOrganisationsRequested,
	GetOrganisationsSuccess,
	ADD_ORGANISATION,
	AddOrganisationFailure,
	AddOrganisationRequested,
	AddOrganisationSuccess,
	UPDATE_ORGANISATION,
	UpdateOrganisationFailure,
	UpdateOrganisationRequested,
	UpdateOrganisationSuccess,
	REMOVE_ORGANISATION,
	RemoveOrganisationFailure,
	RemoveOrganisationRequested,
	RemoveOrganisationSuccess,
	UPDATE_ACCOUNT_ORGANISATION,
	UpdateAccountOrganisationFailure,
	UpdateAccountOrganisationRequested,
	UpdateAccountOrganisationSuccess
} from '../actions/accounts-action-types';

export function* GetAllAccounts({ payload: { orderby, sortby, pageIndex, itemsPerPage } }) {
	yield put(GetAllAccountsRequested());
	const payload = {
		method: 'get',
		url: '/accounts',
		params: { orderby, sortby, pageIndex, itemsPerPage },
	};
	const { result, error } = yield call(httpClient, payload);
	if (error) {
		yield put(GetAllAccountsFailure());
		Toaster('Failed to fetch all accounts', 'error');
	} else {
		yield put(GetAllAccountsSuccess({ itemsPerPage, ...result.payload }));
	}
}

export function* SearchAllAccounts({
	payload: { query, orderby, sortby, pageIndex, itemsPerPage },
}) {
	yield put(SearchAllAccountsRequested());

	const payload = {
		method: 'get',
		url: '/accounts/search',
		params: { orderby, sortby, pageIndex, itemsPerPage, query },
	};
	const { result, error } = yield call(httpClient, payload);

	if (error) {
		yield put(SearchAllAccountsFailure(error));
		Toaster('Failed to fetch all accounts.', 'error');
	} else {
		yield put(SearchAllAccountsSuccess(result.payload));
	}

	return { error, result };
}

export function* RemoveAccount({ payload: { id, callback } }) {
	yield put(RemoveAccountRequested());
	const { result, error } = yield call(httpClient, {
		method: 'delete',
		url: `/accounts/${id}`,
	});

	if (error) {
		yield put(RemoveAccountFailure(error));
		Toaster('Removing account failed.', 'error');
	} else {
		yield put(RemoveAccountSuccess());
		Toaster('Account removed successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

export function* GetAccount({ payload: appAccountId }) {
	yield put(GetAccountRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}`,
	});

	console.log({ result, error });

	if (error) {
		yield put(GetAccountFailure(error));
	} else {
		yield put(GetAccountSuccess(result.payload.account));
	}

	return { error, result };
}

export function* UpdateAccount({ payload: { data, appAccountId } }) {
	yield put(UpdateAccountRequested());
	const { result, error } = yield call(httpClient, {
		data,
		method: 'put',
		url: `/accounts/${appAccountId}`,
	});

	if (error) {
		yield put(UpdateAccountFailure(error));
		Toaster('Account update failed.', 'error');
	} else {
		yield put(UpdateAccountSuccess(result.payload.account));
		Toaster('Account successfully updated.');
	}

	return { error, result };
}

export function* AddAccountContact({ payload: { data, appAccountId, callback } }) {
	yield put(AddAccountContactRequested());
	const { result, error } = yield call(httpClient, {
		data,
		method: 'post',
		url: `/accounts/${appAccountId}/contacts`,
	});

	if (error) {
		yield put(AddAccountContactFailure(error));
		Toaster('Adding account contact failed.', 'error');
	} else {
		yield put(AddAccountContactSuccess(result.payload.account));
		yield call(GetAccount, { payload: appAccountId });
		Toaster('Account contact added successfully.');
	}

	if (callback) callback({ error, result });

	return { error, result };
}

export function* UpdateAccountContact({ payload: { data, appAccountId, appContactId, callback } }) {
	yield put(UpdateAccountContactRequested());
	const { result, error } = yield call(httpClient, {
		data,
		method: 'put',
		url: `/accounts/${appAccountId}/contacts/${appContactId}`,
	});

	if (error) {
		yield put(UpdateAccountContactFailure(error));
		Toaster('Updating account contact failed.', 'error');
	} else {
		yield put(UpdateAccountContactSuccess(result.payload.account));
		yield call(GetAccount, { payload: appAccountId });
		Toaster('Account contact updated successfully.');
	}

	if (callback) callback({ error, result });

	return { error, result };
}

export function* RemoveAccountContact({ payload: { appAccountId, appContactId } }) {
	yield put(RemoveAccountContactRequested());
	const { result, error } = yield call(httpClient, {
		method: 'delete',
		url: `/accounts/${appAccountId}/contacts/${appContactId}`,
	});

	if (error) {
		yield put(RemoveAccountContactFailure(error));
		Toaster('Removing account contact failed.', 'error');
	} else {
		yield put(RemoveAccountContactSuccess());
		yield call(GetAccount, { payload: appAccountId });
		Toaster('Account contact removed successfully.');
	}

	return { error, result };
}

export function* GetAccountUsers({
	payload: { appAccountId, orderby, sortby, pageIndex, itemsPerPage },
}) {
	yield put(GetAccountUsersRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}/users`,
		params: { orderby, sortby, pageIndex, itemsPerPage },
	});

	if (error) {
		yield put(GetAccountUsersFailure(error));

		Toaster(`Failed to fetch account users`, 'error');
	} else {
		yield put(GetAccountUsersSuccess(result.payload));
	}

	return { error, result };
}

export function* SearchAccountUsers({
	payload: { appAccountId, query, orderby, sortby, pageIndex, itemsPerPage, callback },
}) {
	yield put(SearchAccountUsersRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}/users/search`,
		params: { query, orderby, sortby, pageIndex, itemsPerPage },
	});

	console.log({ result, error });

	if (error) {
		yield put(SearchAccountUsersFailure(error));
		Toaster('Failed to fetch account users.', 'error');
	} else {
		yield put(SearchAccountUsersSuccess(result.payload));
	}

	if (callback) callback({ error, result });
	return { error, result };
}

export function* UpdateAccountUser({ payload: { data, appAccountId, AppUserId, callback } }) {
	yield put(UpdateAccountUserRequested());
	const { result, error } = yield call(httpClient, {
		data: data,
		method: 'put',
		url: `/accounts/${appAccountId}/users/${AppUserId}`,
	});

	if (error) {
		yield put(UpdateAccountUserFailure(error));
		Toaster('Updating account user role failed.', 'error');
	} else {
		yield put(UpdateAccountUserSuccess(result));
		Toaster('Account user role updated successfully.');
	}

	if (callback) callback({ error, result });

	return { error, result };
}

export function* RemoveAccountUser({ payload: { appAccountId, appUserId, callback } }) {
	yield put(RemoveAccountUserRequested());
	const { result, error } = yield call(httpClient, {
		method: 'delete',
		url: `/accounts/${appAccountId}/users/${appUserId}`,
	});

	if (error) {
		yield put(RemoveAccountUserFailure(error));
		Toaster('Removing account user failed.', 'error');
	} else {
		yield put(RemoveAccountUserSuccess());
		Toaster('Account user removed successfully.');
	}

	if (callback) callback(error, result);

	return { error, result };
}

export function* GetAccountSubscriptions({ payload: { appAccountId } }) {
	yield put(GetAccountSubscriptionsRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}/subscriptions`,
	});

	if (error) {
		yield put(GetAccountSubscriptionsFailure(error));
		Toaster('Failed to fetch account subscriptions.', 'error');
	} else {
		yield put(GetAccountSubscriptionsSuccess(result.payload));
	}

	return { error, result };
}

export function* GetAccountSubscription({ payload: { appAccountId, appSubscriptionId } }) {
	yield put(GetAccountSubscriptionRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}/subscriptions/${appSubscriptionId}`,
	});

	if (error) {
		yield put(GetAccountSubscriptionFailure(error));
		Toaster('Failed to fetch account subscription.', 'error');
	} else {
		yield put(GetAccountSubscriptionSuccess(result.payload));
	}

	return { error, result };
}

export function* GetAccountSubscriptionUsers({ payload: { appAccountId, appSubscriptionId } }) {
	yield put(GetAccountSubscriptionUsersRequested());

	const { result, error } = yield call(httpClient, {
		method: 'get',
		url: `/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users`,
	});

	if (error) {
		yield put(GetAccountSubscriptionUsersFailure(error));
		Toaster('Failed to fetch account subscription users.', 'error');
	} else {
		yield put(GetAccountSubscriptionUsersSuccess(result.payload));
	}

	return { error, result };
}

export function* AddAccountSubscriptionUser({
	payload: { userId, appAccountId, appSubscriptionId, callback },
}) {
	yield put(AddAccountSubscriptionUserRequested());
	const { result, error } = yield call(httpClient, {
		data: { userId },
		method: 'post',
		url: `/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users`,
	});

	if (error) {
		yield put(AddAccountSubscriptionUserFailure(error));
		Toaster('Adding account subscription user failed.', 'error');
	} else {
		yield put(AddAccountSubscriptionUserSuccess({ appSubscriptionId }));
		Toaster('Account subscription user added successfully.');
	}

	if (callback) callback({ error, result });

	return { error, result };
}

export function* DeleteAccountSubscriptionUser({
	payload: { appAccountId, appSubscriptionId, appUserId, callback },
}) {
	yield put(DeleteAccountSubscriptionUserRequested());
	const { result, error } = yield call(httpClient, {
		method: 'delete',
		url: `/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users/${appUserId}`,
	});

	if (error) {
		yield put(DeleteAccountSubscriptionUserFailure(error));
		Toaster('Removing account subscription user failed.', 'error');
	} else {
		yield put(DeleteAccountSubscriptionUserSuccess({ appSubscriptionId }));
		Toaster('Account subscription user removed successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

export function* AddAccountUser({ payload: { data, appAccountId, callback } }) {
	yield put(AddAccountUserRequested());
	const { result, error } = yield call(httpClient, {
		data,
		method: 'post',
		url: `/accounts/${appAccountId}/users`,
	});
	if (error) {
		yield put(AddAccountUserFailure(error));
		Toaster('User invite failed.', 'error');
	} else {
		yield put(AddAccountUserSuccess());
		Toaster('User invite sent successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

export function* ResetAccountUserMFA({ payload: { appAccountId, appUserId, callback } }) {
	yield put(ResetAccountUserMFARequested());
	const { result, error } = yield call(httpClient, {
		method: 'post',
		url: `/accounts/${appAccountId}/users/${appUserId}/mfa/reset`,
	});

	if (error) {
		yield put(ResetAccountUserMFAFailure(error));
		Toaster('User MFA reset failed.', 'error');
	} else {
		yield put(ResetAccountUserMFASuccess());
		Toaster('User MFA reset successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

export function* ResetUserMFA({ payload: { userId, callback } }) {
	yield put(ResetUserMFARequested());
	const { result, error } = yield call(httpClient, {
		method: 'post',
		url: `/users/${userId}/mfa/reset`,
	});

	if (error) {
		yield put(ResetUserMFAFailure(error));
		Toaster('User MFA reset failed.', 'error');
	} else {
		yield put(ResetUserMFASuccess());
		Toaster('User MFA reset successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

export function* GetOrganisations() {
  yield put(GetOrganisationsRequested());

  const { result, error } = yield call(httpClient, {
    method: 'get',
    url: `/organisations`,
  });

  if (error) {
    yield put(GetOrganisationsFailure(error));
    Toaster('Failed to fetch organisations.', 'error');
  } else {
    yield put(GetOrganisationsSuccess(result.payload));
  }

  return { error, result };
}



export function* AddOrganisation({ payload: { data, callback } }) {
  yield put(AddOrganisationRequested());
  const { result, error } = yield call(httpClient, {
    data,
    method: 'post',
    url: `/organisations`,
  });

  if (error) {
    yield put(AddOrganisationFailure(error));
    Toaster('Adding organization failed.', 'error');
  } else {
    yield put(AddOrganisationSuccess(result.payload));
    yield call(GetOrganisations);
    Toaster('Organization added successfully.');
  }

  if (callback) callback({ error, result });

  return { error, result };
}

export function* UpdateOrganisation({ payload: { data, organisationId, callback } }) {
  yield put(UpdateOrganisationRequested());
  const { result, error } = yield call(httpClient, {
    data,
    method: 'put',
    url: `/organisations/${organisationId}`,
  });

  if (error) {
    yield put(UpdateOrganisationFailure(error));
    Toaster('Updating organization failed.', 'error');
  } else {
    yield put(UpdateOrganisationSuccess(result.payload));
    yield call(GetOrganisations);
    Toaster('Organization updated successfully.');
  }

  if (callback) callback({ error, result });

  return { error, result };
}

export function* RemoveOrganisation({ payload: { organisationId } }) {
  yield put(RemoveOrganisationRequested());
  const { result, error } = yield call(httpClient, {
    method: 'delete',
    url: `/organisations/${organisationId}`,
  });

  if (error) {
    yield put(RemoveOrganisationFailure(error));
  } else {
    yield put(RemoveOrganisationSuccess());
    yield call(GetOrganisations);
    Toaster('Organization removed successfully.');
  }

  return { error, result };
}


export function* UpdateAccountOrganisation({ payload: {data, accountId, callback } }) {
  yield put(UpdateAccountOrganisationRequested());
  const { result, error } = yield call(httpClient, {
  	data,
    method: 'put',
    url: `/accounts/${accountId}/organisation`,
  });

  if (error) {
    yield put(UpdateAccountOrganisationFailure(error));
    Toaster('Failed to update account organization.', 'error');
  } else {
    yield put(UpdateAccountOrganisationSuccess());
    Toaster('Account organization updated successfully.');
  }
  if (callback) callback(error, result);
  return { error, result };
}



function* Accounts() {
	yield all([
		debounce(400, GET_ALL_ACCOUNTS, GetAllAccounts),
		debounce(400, SEARCH_ALL_ACCOUNTS, SearchAllAccounts),
		takeLatest(REMOVE_ACCOUNT, RemoveAccount),
		takeLatest(UPDATE_ACCOUNT, UpdateAccount),
		takeLatest(GET_ACCOUNT, GetAccount),
		takeLatest(ADD_ACCOUNT_CONTACT, AddAccountContact),
		takeLatest(UPDATE_ACCOUNT_CONTACT, UpdateAccountContact),
		takeLatest(REMOVE_ACCOUNT_CONTACT, RemoveAccountContact),
		debounce(400, GET_ACCOUNT_USERS, GetAccountUsers),
		debounce(400, SEARCH_ACCOUNT_USERS, SearchAccountUsers),
		takeLatest(UPDATE_ACCOUNT_USER, UpdateAccountUser),
		takeLatest(REMOVE_ACCOUNT_USER, RemoveAccountUser),
		takeLatest(GET_ACCOUNT_SUBSCRIPTIONS, GetAccountSubscriptions),
		takeLatest(GET_ACCOUNT_SUBSCRIPTION, GetAccountSubscription),
		takeLatest(GET_ACCOUNT_SUBSCRIPTION_USERS, GetAccountSubscriptionUsers),
		takeLatest(ADD_ACCOUNT_SUBSCRIPTION_USER, AddAccountSubscriptionUser),
		takeLatest(DELETE_ACCOUNT_SUBSCRIPTION_USER, DeleteAccountSubscriptionUser),
		takeLatest(ADD_ACCOUNT_USER, AddAccountUser),
		takeLatest(RESET_ACCOUNT_USER_MFA, ResetAccountUserMFA),
		takeLatest(RESET_USER_MFA, ResetUserMFA),
		takeLatest(GET_ORGANISATIONS, GetOrganisations),
		takeLatest(ADD_ORGANISATION, AddOrganisation),
		takeLatest(UPDATE_ORGANISATION, UpdateOrganisation),
		takeLatest(REMOVE_ORGANISATION, RemoveOrganisation),
		takeLatest(UPDATE_ACCOUNT_ORGANISATION, UpdateAccountOrganisation),
	]);
}

export default Accounts;
