import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../form-fields";
import { useUpdateUseCaseMutation, useAddUseCaseMutation } from "@/redux/toolkit/useCasesSlice";

const AddUseCaseSchema = Yup.object({
  name: Yup.string().required("Use case name is a required field"),
  category: Yup.string().required("category name is a required field")
});

function AddUpdateUseCasesModal({ hide, initialValues = false }) {

  const [addUseCase] = useAddUseCaseMutation();
  const [updateUseCase] = useUpdateUseCaseMutation();
  const isEditMode = Boolean(initialValues);
  const blankFormValues = {
    name: ""
  };

  const formInitialValues = initialValues ? initialValues : blankFormValues;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async(values, actions) => {
    setIsSubmitting(true);
    if (isEditMode) {
      await updateUseCase({data: values, useCaseId: initialValues.id});
    } else {
      await addUseCase({data: values});
    }
    actions.resetForm(blankFormValues);
    hide(true);
    setIsSubmitting(false);
  };

  return (
    <Formik
      key={
        initialValues
          ? initialValues.name
          : "AddUpdateUseCaseForm"
      }
      initialValues={formInitialValues}
      validationSchema={AddUseCaseSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {isEditMode ? `Edit Use case` : `Add Use case`}
            </h5>
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1"/>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form className="form needs-validation">
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="Name" name="name" type="text" placeholder="Name" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput label="Category" name="category" type="text" placeholder="Category" className="form-control" />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={hide}>
              Cancel
            </button>
            <Button
              label={"Save"}
              className="btn btn-primary"
              dirty={dirty}
              errors={errors}
              isValidating={isValidating}
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Formik>
  );
}

export default AddUpdateUseCasesModal;
