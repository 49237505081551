import React from 'react';
const TableLoader = ({ fetching, colSpan }) => {
  return (
    fetching
      ?
      <tr>
        <td colSpan={colSpan}>
          <div className="loader d-flex flex-column align-items-center justify-content-center my-4">
            <p className="text-muted m-0"><i className="fal fa-lg fa-spinner fa-fast-spin" aria-hidden="true"></i></p>
          </div>
        </td>
      </tr>
      : null
  )
}

export default TableLoader;