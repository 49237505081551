import { createAction } from 'redux-actions';

// GetAllUsers
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GetAllUsers = createAction(GET_ALL_USERS);
export const GET_ALL_USERS_REQUESTED = 'GET_ALL_USERS_REQUESTED';
export const GetAllUsersRequested = createAction(GET_ALL_USERS_REQUESTED);
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
export const GetAllUsersFailure = createAction(GET_ALL_USERS_FAILURE);
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GetAllUsersSuccess = createAction(GET_ALL_USERS_SUCCESS);

// SearchAllUsers
export const SEARCH_ALL_USERS = 'SEARCH_ALL_USERS';
export const SearchAllUsers = createAction(SEARCH_ALL_USERS);
export const SEARCH_ALL_USERS_REQUESTED = 'SEARCH_ALL_USERS_REQUESTED';
export const SearchAllUsersRequested = createAction(SEARCH_ALL_USERS_REQUESTED);
export const SEARCH_ALL_USERS_FAILURE = 'SEARCH_ALL_USERS_FAILURE';
export const SearchAllUsersFailure = createAction(SEARCH_ALL_USERS_FAILURE);
export const SEARCH_ALL_USERS_SUCCESS = 'SEARCH_ALL_USERS_SUCCESS';
export const SearchAllUsersSuccess = createAction(SEARCH_ALL_USERS_SUCCESS);

// RemoveUser
export const REMOVE_USER = 'REMOVE_USER';
export const RemoveUser = createAction(REMOVE_USER);
export const REMOVE_USER_REQUESTED = 'REMOVE_USER_REQUESTED';
export const RemoveUserRequested = createAction(REMOVE_USER_REQUESTED);
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE';
export const RemoveUserFailure = createAction(REMOVE_USER_FAILURE);
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const RemoveUserSuccess = createAction(REMOVE_USER_SUCCESS);
