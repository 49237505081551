import { Field, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "../form-fields";
import { useUpdateAccountUserMutation } from "@/redux/toolkit/accountSlice";
import { Toaster } from '@/components/Toast';

const UserRoleSchema = Yup.object({
  role: Yup.string().oneOf(["admin", "user"]).required("Role is required."),
});

function ChangeUserRoleModal({ hide, user }) {

  const [ updateAccountUser ] = useUpdateAccountUserMutation();
  const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const initialValues = { role: user.role, type: user.type };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values, actions) => {
    setIsSubmitting(true);
    try {
      updateAccountUser({ data: values, appAccountId, AppUserId: user.id }).unwrap();
      actions.resetForm();
      hide(true);
      Toaster('Account user role updated successfully.');
    } catch (error) {
      Toaster('Updating account user role failed.', "error");
    }
    setIsSubmitting(false);
  };

  return (
    <Formik key={initialValues.role} initialValues={initialValues} validationSchema={UserRoleSchema} onSubmit={onSubmit}>
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Change role
            </h5>
            <button type="button" className="close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p>{`Change role for user ${user.firstName} ${user.lastName}.`}</p>
            <form className="form needs-validation" action="#" style={{ width: "420px" }} noValidate>
              <div className="form-group mb-3">
                <label htmlFor="role">Role</label>
                <Field as="select" name="role" className="custom-select">
                  <option value={"admin"}>Admin</option>
                  <option value={"user"}>User</option>
                </Field>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
              Cancel
            </button>
            <Button
              label={"Save"}
              className="btn btn-primary"
              dirty={dirty}
              errors={errors}
              isValidating={isValidating}
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Formik>
  );
}

export default ChangeUserRoleModal;
