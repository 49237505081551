import { ReduxRouter } from "@lagunovsky/redux-react-router"
import React, {useEffect} from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/es/integration/react";
import Navigation from "./navigation";
import { IntercomProvider } from "react-use-intercom";
import { history } from "./config/history";
import { store, persistor } from "./redux/toolkit/store";

const routerSelector = (state) => state.router;

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

const App = () => {
  useEffect(() => {
    let user = store.getState().session?.user;
    let session = store.getState().session;
    let productAccess = store.getState().session?.productAccess;

    /** Call segment identify call on every page */
    if (productAccess?.includes("admin")) {
      let { id, email, firstName, lastName, createdAt } = user;
      window.analytics.identify(id, { email, firstName, lastName, name: `${firstName} ${lastName}`, createdAt, id });
      let segmentData = {
        groupType: "account",
        id: session.account && session.account.id ? session.account.id : "",
        name: session.account && session.account.company ? session.account.company : "",
        type: session.account && session.account.type ? session.account.type : "",
        tier: session.account && session.account.tier ? session.account.tier : "",
        createdAt: session.account && session.account.createdAt ? session.account.createdAt : "",
      };

      if(session.account && session.account.organisation && session.account.organisation.id) {
        segmentData.parentGroupId = session.account.organisation.id;
      }

      window.analytics.group(session.account && session.account.id ? session.account.id : "", {
        ...segmentData
        },
        {
          groupId: session.account && session.account.id ? session.account.id : "",
          userId: id ? id : ""
        });

      if(session.account && session.account.organisation && session.account.organisation.id) {
        window.analytics.group(session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "", {
          groupType: "organization",
          id: session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "",
          name: session.account && session.account.organisation && session.account.organisation.name ? session.account.organisation.name : "",
          createdAt: session.account && session.account.organisation && session.account.organisation.createdAt ? session.account.organisation.createdAt : "",
          updatedAt: session.account && session.account.organisation && session.account.organisation.updatedAt ? session.account.organisation.updatedAt : "",
        });
      }
    }

    //if user has old encrypted local storage, clear it
    if (localStorage.getItem("persist:primary")) {
      localStorage.removeItem("persist:primary");
    }
  }, [])
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <ReduxRouter history={history} routerSelector={routerSelector}>
            <Navigation />
          </ReduxRouter>
        </PersistGate>
      </Provider>
    </IntercomProvider>
  );
};
export default App;