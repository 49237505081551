import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function NotFound() {
  const role = useSelector((state) => state.session?.role);
  const accountId = useSelector((state) => state.session?.account?.id);

  const mapRoleToLandingPage = (role) => {
    const PublicLandingPage = "/";
    const UserLandingPage = "/profile/basic-info";
    const AdminLandingPage = `/accounts/${accountId}/details`;
    const SuperAdminLandingPage = "/accounts";

    switch (role) {
      case "super":
        return SuperAdminLandingPage;

      case "admin":
        return AdminLandingPage;

      case "user":
        return UserLandingPage;

      case null:
        return PublicLandingPage;

      default:
        break;
    }
  };

  const redirectTo = mapRoleToLandingPage(role);

  return (
    <main>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mt-4">
              <p className="lead">
                This requested URL was not found on this server.
              </p>
              <NavLink to={redirectTo}>
                <i className="fas fa-arrow-left mr-1"></i>Return to Dashboard
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
