import React from "react";
import { RemoveUseCaseModal, ToggleModal, AddUpdateUseCasesModal } from "../components/Modals";
import SubHeader from "../components/parts/SubHeader";
import { Table } from "../components/Table";
import { useGetUseCasesQuery } from "@/redux/toolkit/useCasesSlice";

function UseCases() {
  const {data: {useCases}={}, isLoading, isSuccess:isFetched } = useGetUseCasesQuery();

  return (
    <>
      <SubHeader>
        <h1>Use Cases</h1>
        <p className="text-muted m-0" />
      </SubHeader>
      <hr className="m-0" />
      <section className="bg-white">
        <div className="container py-5">
          <ToggleModal
            toggle={(show) => (
              <button
                type='button'
                className='float-right btn btn-outline-primary float-right mb-4'
                onClick={show}
              >
                Add Use case
              </button>
            )}
            content={(hide) => <AddUpdateUseCasesModal hide={hide} />}
          />
          <div className="table-responsive my-4">
            <Table
              isLoading={isLoading}
              isFetched={isFetched}
              dataSource={useCases}
              colMapper={[
                {
                  title: "Name",
                  keyName: "name",
                  cellRender: ({ name, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {name}
                      <br />
                      <small className="text-muted">
                        <b>ID</b> {id}
                      </small>
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "Category",
                  keyName: "category",
                  cellRender: ({ category, id }, key) => (
                    <td className="text-nowrap" key={key}>
                      {category}
                    </td>
                  ),
                  width: "315px",
                },
                {
                  title: "",
                  cellRender: (useCase, key) => (
                    <td className="align-middle text-right" key={key}>
                      {useCase.name.toLowerCase() !== "other" &&
                      <div className="dropdown dropdown-sm">
                        <button
                          className="btn btn-link btn-sm"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="far fa-lg fa-ellipsis-v"/>
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <ToggleModal
                            toggle={(show) => (
                              <span className="dropdown-item pointer" onClick={show}>
                                Edit
                              </span>
                            )}
                            content={(hide) => <AddUpdateUseCasesModal hide={hide} initialValues={{
                              id: useCase.id,
                              name: useCase.name,
                              category: useCase.category
                            }}/>}
                          />
                          <div className="dropdown-divider"/>
                          <ToggleModal
                            toggle={(show) => (
                              <span className="dropdown-item pointer text-danger" onClick={show}>
                                Remove
                              </span>
                            )}
                            content={(hide) => (
                              <RemoveUseCaseModal
                                hide={(deleteSuccess = false) => {
                                  hide();
                                }}
                                useCase={useCase}
                                method="removeOrganisation"
                              />
                            )}
                          />
                        </div>
                      </div>
                      }
                    </td>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default UseCases;
