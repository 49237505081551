import React from "react";

function Pagination({ maxPageIndex, pageIndex, totalRecords, pageLimit, pageNeighbours = 2, onPageChanged, onLimitChange }) {
  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";
  const LEFT_RANGE = "LEFT_RANGE";
  const RIGHT_RANGE = "RIGHT_RANGE";

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const limitValues = [
    {
      value: "5",
      label: "Show 5",
    },
    {
      value: "10",
      label: "Show 10",
    },
    {
      value: "20",
      label: "Show 20",
    },
    {
      value: "50",
      label: "Show 50",
    },
  ];

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  };

  const fetchPageNumbers = () => {
    const currentPage = pageIndex;
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [1, LEFT_RANGE, ...extraPages, ...pages, totalPages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [1, ...pages, ...extraPages, RIGHT_RANGE, totalPages];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [1, LEFT_RANGE, ...pages, RIGHT_RANGE, totalPages];
          break;
        }
      }

      // return pages;
      return [LEFT_PAGE, ...pages, RIGHT_PAGE];
    }

    return [LEFT_PAGE, ...range(1, totalPages), RIGHT_PAGE];
  };

  const pages = fetchPageNumbers();
  const canPreviousPage = Boolean(pageIndex !== 1);
  const canNextPage = Boolean(pageIndex !== maxPageIndex);

  return (
    <>
      <span className="navbar-text d-inline-block mb-2 mb-md-0">
        Page {pageIndex} of {maxPageIndex} ({totalRecords} results)
      </span>
      <nav className="d-lg-none mb-3 mb-lg-0 ml-md-auto mr-md-2" aria-label="Page navigation example">
        <ul className="pagination justify-content-left mb-0">
          <li className={canPreviousPage ? "page-item" : "page-item disabled"}>
            <button
              className="page-link"
              aria-label="Previous"
              tabIndex="-1"
              aria-disabled={canPreviousPage ? "false" : "true"}
              onClick={() => onPageChanged({ currentPage: pageIndex - 1 })}
            >
              <i className="far fa-fw fa-angle-left"></i>
            </button>
          </li>
          <li className="page-item">
            <span className="page-link">
              Page {pageIndex} of {maxPageIndex}
            </span>
          </li>
          <li className={canNextPage ? "page-item" : "page-item disabled"}>
            <button
              className="page-link"
              aria-label="Next"
              onClick={() => onPageChanged({ currentPage: pageIndex + 1 })}
              aria-disabled={canNextPage ? "false" : "true"}
            >
              <i className="far fa-fw fa-angle-right"></i>
            </button>
          </li>
        </ul>
      </nav>
      <div className="collapse navbar-collapse show" id="navbarSupportedContent">
        <nav className="d-none d-lg-block mb-3 mb-lg-0 ml-md-auto mr-md-2" aria-label="Page navigation example">
          <ul className="pagination justify-content-left mb-0">
            {pages.map((page, index) => {
              if (page === LEFT_RANGE || page === RIGHT_RANGE)
                return (
                  <li key={index} className="page-item disabled">
                    <button className="page-link">...</button>
                  </li>
                );

              if (page === LEFT_PAGE)
                return (
                  <li key={index} className={canPreviousPage ? "page-item" : "page-item disabled"}>
                    <button className="page-link" aria-label="Previous" onClick={() => onPageChanged({ currentPage: pageIndex - 1 })} tabIndex="-1">
                      <i className="far fa-angle-left"></i>
                    </button>
                  </li>
                );

              if (page === RIGHT_PAGE)
                return (
                  <li key={index} className={canNextPage ? "page-item" : "page-item disabled"}>
                    <button className="page-link" aria-label="Next" onClick={() => onPageChanged({ currentPage: pageIndex + 1 })} tabIndex="-1">
                      <i className="far fa-angle-right"></i>
                    </button>
                  </li>
                );

              return (
                <li key={index} className={`page-item${pageIndex === page ? " active" : ""}`}>
                  <button className="page-link" href="#" onClick={() => onPageChanged({ currentPage: page })}>
                    {page}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <form className="form-inline mb-3 mb-md-0">
        <select defaultValue={pageLimit} className="custom-select" onChange={(e) => onLimitChange(Number(e.target.value))}>
          {limitValues.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
      </form>
    </>
  );
}

export default Pagination;
