import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../form-fields";
import { useCloneContextPackMutation } from "@/redux/toolkit/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { Toaster } from '@/components/Toast';

function CloneContextPack({ hide, data: { accountId } }) {
  const [ cloneContextPack ] = useCloneContextPackMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const sourceAccountId = `${process.env.REACT_APP_SOURCE_ACCOUNT_ID}`;

  useEffect(() => {
    setIsSubmitting(false);
    if(isSuccess){
      hide(true);
    }
  }, [isSuccess, hide]);
  const onSubmit = async(value) => {
    setIsSubmitting(true);
    const ids = value.id.split(",");
    for(const id of ids) {
      if(id.trim().length) {
        try {
          await cloneContextPack({
            destinationAccountId: accountId,
            sourceContextPackId: id.trim(),
            sourceAccountId: sourceAccountId
          }).unwrap();
          Toaster('Context pack/s cloned successfully.');
        } catch (error) {
          Toaster(error.data.error.message, 'error');
        }
      }
    }
    setIsSuccess(true);
  };

  return (
    <Formik
      initialValues={{ id: "" }}
      validationSchema={Yup.object({
        id: Yup.string()
          .required("Id is required."),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Apply Context Pack
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1" />
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-4">
                  <div className=" col-12">
                    <div className="form-label-group">
                      <TextInput label="Id" name="id" type="text" placeholder="Id" className="form-control" />
                    </div>
                    <div role="alert" className="fade fs-sm p-3 pl-5 position-relative mt-2 alert alert-info show">
                      <FontAwesomeIcon size="xl" className="position-absolute canvas-visualization m-3" icon={faInfoCircle} />
                      <p className="readable-md ms-2">
                        Applying a context pack/s from the 'Benchmark Account Dragonfly' account will take a snapshot of the pack and
                        create a new one in your selected account. <br />
                        You can copy one by simply pasting the id of the pack or multiple with a comma separated list e.g.{" "}
                      </p>
                      <p className="mb-0 text-danger">
                        205a675c-bf61-4905-97b5-41db3c1925e7,<br />
                        741f1512-32f3-4555-83fc-812180d24ff5,<br />
                        19f0da1e-6ab7-499b-98bf-e5215c78ddd3,<br />
                        de25b54f-6052-405e-b259-7260689b554e,<br />
                        7f253cd2-8e91-405f-b491-f134ca04d422
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Apply"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Applying"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CloneContextPack;
