import { createAction } from 'redux-actions';

// GetAllAccounts
export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS';
export const GetAllAccounts = createAction(GET_ALL_ACCOUNTS);
export const GET_ALL_ACCOUNTS_REQUESTED = 'GET_ALL_ACCOUNTS_REQUESTED';
export const GetAllAccountsRequested = createAction(GET_ALL_ACCOUNTS_REQUESTED);
export const GET_ALL_ACCOUNTS_FAILURE = 'GET_ALL_ACCOUNTS_FAILURE';
export const GetAllAccountsFailure = createAction(GET_ALL_ACCOUNTS_FAILURE);
export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';
export const GetAllAccountsSuccess = createAction(GET_ALL_ACCOUNTS_SUCCESS);

// SearchAllAccounts
export const SEARCH_ALL_ACCOUNTS = 'SEARCH_ALL_ACCOUNTS';
export const SearchAllAccounts = createAction(SEARCH_ALL_ACCOUNTS);
export const SEARCH_ALL_ACCOUNTS_REQUESTED = 'SEARCH_ALL_ACCOUNTS_REQUESTED';
export const SearchAllAccountsRequested = createAction(SEARCH_ALL_ACCOUNTS_REQUESTED);
export const SEARCH_ALL_ACCOUNTS_FAILURE = 'SEARCH_ALL_ACCOUNTS_FAILURE';
export const SearchAllAccountsFailure = createAction(SEARCH_ALL_ACCOUNTS_FAILURE);
export const SEARCH_ALL_ACCOUNTS_SUCCESS = 'SEARCH_ALL_ACCOUNTS_SUCCESS';
export const SearchAllAccountsSuccess = createAction(SEARCH_ALL_ACCOUNTS_SUCCESS);

// RemoveAccount
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const RemoveAccount = createAction(REMOVE_ACCOUNT);
export const REMOVE_ACCOUNT_REQUESTED = 'REMOVE_ACCOUNT_REQUESTED';
export const RemoveAccountRequested = createAction(REMOVE_ACCOUNT_REQUESTED);
export const REMOVE_ACCOUNT_FAILURE = 'REMOVE_ACCOUNT_FAILURE';
export const RemoveAccountFailure = createAction(REMOVE_ACCOUNT_FAILURE);
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS';
export const RemoveAccountSuccess = createAction(REMOVE_ACCOUNT_SUCCESS);

// UpdateAccount
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UpdateAccount = createAction(UPDATE_ACCOUNT);
export const UPDATE_ACCOUNT_REQUESTED = 'UPDATE_ACCOUNT_REQUESTED';
export const UpdateAccountRequested = createAction(UPDATE_ACCOUNT_REQUESTED);
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';
export const UpdateAccountFailure = createAction(UPDATE_ACCOUNT_FAILURE);
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UpdateAccountSuccess = createAction(UPDATE_ACCOUNT_SUCCESS);

// GetAccount
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GetAccount = createAction(GET_ACCOUNT);
export const GET_ACCOUNT_REQUESTED = 'GET_ACCOUNT_REQUESTED';
export const GetAccountRequested = createAction(GET_ACCOUNT_REQUESTED);
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';
export const GetAccountFailure = createAction(GET_ACCOUNT_FAILURE);
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GetAccountSuccess = createAction(GET_ACCOUNT_SUCCESS);

// AddAccountContact
export const ADD_ACCOUNT_CONTACT = 'ADD_ACCOUNT_CONTACT';
export const AddAccountContact = createAction(ADD_ACCOUNT_CONTACT);
export const ADD_ACCOUNT_CONTACT_REQUESTED = 'ADD_ACCOUNT_CONTACT_REQUESTED';
export const AddAccountContactRequested = createAction(ADD_ACCOUNT_CONTACT_REQUESTED);
export const ADD_ACCOUNT_CONTACT_FAILURE = 'ADD_ACCOUNT_CONTACT_FAILURE';
export const AddAccountContactFailure = createAction(ADD_ACCOUNT_CONTACT_FAILURE);
export const ADD_ACCOUNT_CONTACT_SUCCESS = 'ADD_ACCOUNT_CONTACT_SUCCESS';
export const AddAccountContactSuccess = createAction(ADD_ACCOUNT_CONTACT_SUCCESS);

// UpdateAccountContact
export const UPDATE_ACCOUNT_CONTACT = 'UPDATE_ACCOUNT_CONTACT';
export const UpdateAccountContact = createAction(UPDATE_ACCOUNT_CONTACT);
export const UPDATE_ACCOUNT_CONTACT_REQUESTED = 'UPDATE_ACCOUNT_CONTACT_REQUESTED';
export const UpdateAccountContactRequested = createAction(UPDATE_ACCOUNT_CONTACT_REQUESTED);
export const UPDATE_ACCOUNT_CONTACT_FAILURE = 'UPDATE_ACCOUNT_CONTACT_FAILURE';
export const UpdateAccountContactFailure = createAction(UPDATE_ACCOUNT_CONTACT_FAILURE);
export const UPDATE_ACCOUNT_CONTACT_SUCCESS = 'UPDATE_ACCOUNT_CONTACT_SUCCESS';
export const UpdateAccountContactSuccess = createAction(UPDATE_ACCOUNT_CONTACT_SUCCESS);

// RemoveAccountContact
export const REMOVE_ACCOUNT_CONTACT = 'REMOVE_ACCOUNT_CONTACT';
export const RemoveAccountContact = createAction(REMOVE_ACCOUNT_CONTACT);
export const REMOVE_ACCOUNT_CONTACT_REQUESTED = 'REMOVE_ACCOUNT_CONTACT_REQUESTED';
export const RemoveAccountContactRequested = createAction(REMOVE_ACCOUNT_CONTACT_REQUESTED);
export const REMOVE_ACCOUNT_CONTACT_FAILURE = 'REMOVE_ACCOUNT_CONTACT_FAILURE';
export const RemoveAccountContactFailure = createAction(REMOVE_ACCOUNT_CONTACT_FAILURE);
export const REMOVE_ACCOUNT_CONTACT_SUCCESS = 'REMOVE_ACCOUNT_CONTACT_SUCCESS';
export const RemoveAccountContactSuccess = createAction(REMOVE_ACCOUNT_CONTACT_SUCCESS);

// GetAccountUsers
export const GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS';
export const GetAccountUsers = createAction(GET_ACCOUNT_USERS);
export const GET_ACCOUNT_USERS_REQUESTED = 'GET_ACCOUNT_USERS_REQUESTED';
export const GetAccountUsersRequested = createAction(GET_ACCOUNT_USERS_REQUESTED);
export const GET_ACCOUNT_USERS_FAILURE = 'GET_ACCOUNT_USERS_FAILURE';
export const GetAccountUsersFailure = createAction(GET_ACCOUNT_USERS_FAILURE);
export const GET_ACCOUNT_USERS_SUCCESS = 'GET_ACCOUNT_USERS_SUCCESS';
export const GetAccountUsersSuccess = createAction(GET_ACCOUNT_USERS_SUCCESS);

// SearchAccountUsers
export const SEARCH_ACCOUNT_USERS = 'SEARCH_ACCOUNT_USERS';
export const SearchAccountUsers = createAction(SEARCH_ACCOUNT_USERS);
export const SEARCH_ACCOUNT_USERS_REQUESTED = 'SEARCH_ACCOUNT_USERS_REQUESTED';
export const SearchAccountUsersRequested = createAction(SEARCH_ACCOUNT_USERS_REQUESTED);
export const SEARCH_ACCOUNT_USERS_FAILURE = 'SEARCH_ACCOUNT_USERS_FAILURE';
export const SearchAccountUsersFailure = createAction(SEARCH_ACCOUNT_USERS_FAILURE);
export const SEARCH_ACCOUNT_USERS_SUCCESS = 'SEARCH_ACCOUNT_USERS_SUCCESS';
export const SearchAccountUsersSuccess = createAction(SEARCH_ACCOUNT_USERS_SUCCESS);

// UpdateAccountUser
export const UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER';
export const UpdateAccountUser = createAction(UPDATE_ACCOUNT_USER);
export const UPDATE_ACCOUNT_USER_REQUESTED = 'UPDATE_ACCOUNT_USER_REQUESTED';
export const UpdateAccountUserRequested = createAction(UPDATE_ACCOUNT_USER_REQUESTED);
export const UPDATE_ACCOUNT_USER_FAILURE = 'UPDATE_ACCOUNT_USER_FAILURE';
export const UpdateAccountUserFailure = createAction(UPDATE_ACCOUNT_USER_FAILURE);
export const UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS';
export const UpdateAccountUserSuccess = createAction(UPDATE_ACCOUNT_USER_SUCCESS);

// RemoveAccountUser
export const REMOVE_ACCOUNT_USER = 'REMOVE_ACCOUNT_USER';
export const RemoveAccountUser = createAction(REMOVE_ACCOUNT_USER);
export const REMOVE_ACCOUNT_USER_REQUESTED = 'REMOVE_ACCOUNT_USER_REQUESTED';
export const RemoveAccountUserRequested = createAction(REMOVE_ACCOUNT_USER_REQUESTED);
export const REMOVE_ACCOUNT_USER_FAILURE = 'REMOVE_ACCOUNT_USER_FAILURE';
export const RemoveAccountUserFailure = createAction(REMOVE_ACCOUNT_USER_FAILURE);
export const REMOVE_ACCOUNT_USER_SUCCESS = 'REMOVE_ACCOUNT_USER_SUCCESS';
export const RemoveAccountUserSuccess = createAction(REMOVE_ACCOUNT_USER_SUCCESS);

// GetAccountSubscriptions
export const GET_ACCOUNT_SUBSCRIPTIONS = 'GET_ACCOUNT_SUBSCRIPTIONS';
export const GetAccountSubscriptions = createAction(GET_ACCOUNT_SUBSCRIPTIONS);
export const GET_ACCOUNT_SUBSCRIPTIONS_REQUESTED = 'GET_ACCOUNT_SUBSCRIPTIONS_REQUESTED';
export const GetAccountSubscriptionsRequested = createAction(GET_ACCOUNT_SUBSCRIPTIONS_REQUESTED);
export const GET_ACCOUNT_SUBSCRIPTIONS_FAILURE = 'GET_ACCOUNT_SUBSCRIPTIONS_FAILURE';
export const GetAccountSubscriptionsFailure = createAction(GET_ACCOUNT_SUBSCRIPTIONS_FAILURE);
export const GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS = 'GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS';
export const GetAccountSubscriptionsSuccess = createAction(GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS);

// GetAccountSubscription
export const GET_ACCOUNT_SUBSCRIPTION = 'GET_ACCOUNT_SUBSCRIPTION';
export const GetAccountSubscription = createAction(GET_ACCOUNT_SUBSCRIPTION);
export const GET_ACCOUNT_SUBSCRIPTION_REQUESTED = 'GET_ACCOUNT_SUBSCRIPTION_REQUESTED';
export const GetAccountSubscriptionRequested = createAction(GET_ACCOUNT_SUBSCRIPTION_REQUESTED);
export const GET_ACCOUNT_SUBSCRIPTION_FAILURE = 'GET_ACCOUNT_SUBSCRIPTION_FAILURE';
export const GetAccountSubscriptionFailure = createAction(GET_ACCOUNT_SUBSCRIPTION_FAILURE);
export const GET_ACCOUNT_SUBSCRIPTION_SUCCESS = 'GET_ACCOUNT_SUBSCRIPTION_SUCCESS';
export const GetAccountSubscriptionSuccess = createAction(GET_ACCOUNT_SUBSCRIPTION_SUCCESS);

// GetAccountSubscriptionUsers
export const GET_ACCOUNT_SUBSCRIPTION_USERS = 'GET_ACCOUNT_SUBSCRIPTION_USERS';
export const GetAccountSubscriptionUsers = createAction(GET_ACCOUNT_SUBSCRIPTION_USERS);
export const GET_ACCOUNT_SUBSCRIPTION_USERS_REQUESTED = 'GET_ACCOUNT_SUBSCRIPTION_USERS_REQUESTED';
export const GetAccountSubscriptionUsersRequested = createAction(
	GET_ACCOUNT_SUBSCRIPTION_USERS_REQUESTED
);
export const GET_ACCOUNT_SUBSCRIPTION_USERS_FAILURE = 'GET_ACCOUNT_SUBSCRIPTION_USERS_FAILURE';
export const GetAccountSubscriptionUsersFailure = createAction(
	GET_ACCOUNT_SUBSCRIPTION_USERS_FAILURE
);
export const GET_ACCOUNT_SUBSCRIPTION_USERS_SUCCESS = 'GET_ACCOUNT_SUBSCRIPTION_USERS_SUCCESS';
export const GetAccountSubscriptionUsersSuccess = createAction(
	GET_ACCOUNT_SUBSCRIPTION_USERS_SUCCESS
);

// AddAccountSubscriptionUser
export const ADD_ACCOUNT_SUBSCRIPTION_USER = 'ADD_ACCOUNT_SUBSCRIPTION_USER';
export const AddAccountSubscriptionUser = createAction(ADD_ACCOUNT_SUBSCRIPTION_USER);
export const ADD_ACCOUNT_SUBSCRIPTION_USER_REQUESTED = 'ADD_ACCOUNT_SUBSCRIPTION_USER_REQUESTED';
export const AddAccountSubscriptionUserRequested = createAction(
	ADD_ACCOUNT_SUBSCRIPTION_USER_REQUESTED
);
export const ADD_ACCOUNT_SUBSCRIPTION_USER_FAILURE = 'ADD_ACCOUNT_SUBSCRIPTION_USER_FAILURE';
export const AddAccountSubscriptionUserFailure = createAction(
	ADD_ACCOUNT_SUBSCRIPTION_USER_FAILURE
);
export const ADD_ACCOUNT_SUBSCRIPTION_USER_SUCCESS = 'ADD_ACCOUNT_SUBSCRIPTION_USER_SUCCESS';
export const AddAccountSubscriptionUserSuccess = createAction(
	ADD_ACCOUNT_SUBSCRIPTION_USER_SUCCESS
);

// DeleteAccountSubscriptionUser
export const DELETE_ACCOUNT_SUBSCRIPTION_USER = 'DELETE_ACCOUNT_SUBSCRIPTION_USER';
export const DeleteAccountSubscriptionUser = createAction(DELETE_ACCOUNT_SUBSCRIPTION_USER);
export const DELETE_ACCOUNT_SUBSCRIPTION_USER_REQUESTED =
	'DELETE_ACCOUNT_SUBSCRIPTION_USER_REQUESTED';
export const DeleteAccountSubscriptionUserRequested = createAction(
	DELETE_ACCOUNT_SUBSCRIPTION_USER_REQUESTED
);
export const DELETE_ACCOUNT_SUBSCRIPTION_USER_FAILURE = 'DELETE_ACCOUNT_SUBSCRIPTION_USER_FAILURE';
export const DeleteAccountSubscriptionUserFailure = createAction(
	DELETE_ACCOUNT_SUBSCRIPTION_USER_FAILURE
);
export const DELETE_ACCOUNT_SUBSCRIPTION_USER_SUCCESS = 'DELETE_ACCOUNT_SUBSCRIPTION_USER_SUCCESS';
export const DeleteAccountSubscriptionUserSuccess = createAction(
	DELETE_ACCOUNT_SUBSCRIPTION_USER_SUCCESS
);

// AddAccountUser
export const ADD_ACCOUNT_USER = 'ADD_ACCOUNT_USER';
export const AddAccountUser = createAction(ADD_ACCOUNT_USER);
export const ADD_ACCOUNT_USER_REQUESTED = 'ADD_ACCOUNT_USER_REQUESTED';
export const AddAccountUserRequested = createAction(ADD_ACCOUNT_USER_REQUESTED);
export const ADD_ACCOUNT_USER_FAILURE = 'ADD_ACCOUNT_USER_FAILURE';
export const AddAccountUserFailure = createAction(ADD_ACCOUNT_USER_FAILURE);
export const ADD_ACCOUNT_USER_SUCCESS = 'ADD_ACCOUNT_USER_SUCCESS';
export const AddAccountUserSuccess = createAction(ADD_ACCOUNT_USER_SUCCESS);

// ResetAccountUserMFA
export const RESET_ACCOUNT_USER_MFA = 'RESET_ACCOUNT_USER_MFA';
export const ResetAccountUserMFA = createAction(RESET_ACCOUNT_USER_MFA);
export const RESET_ACCOUNT_USER_MFA_REQUESTED = 'RESET_ACCOUNT_USER_MFA_REQUESTED';
export const ResetAccountUserMFARequested = createAction(RESET_ACCOUNT_USER_MFA_REQUESTED);
export const RESET_ACCOUNT_USER_MFA_FAILURE = 'RESET_ACCOUNT_USER_MFA_FAILURE';
export const ResetAccountUserMFAFailure = createAction(RESET_ACCOUNT_USER_MFA_FAILURE);
export const RESET_ACCOUNT_USER_MFA_SUCCESS = 'RESET_ACCOUNT_USER_MFA_SUCCESS';
export const ResetAccountUserMFASuccess = createAction(RESET_ACCOUNT_USER_MFA_SUCCESS);

// ResetUserMFA
export const RESET_USER_MFA = 'RESET_USER_MFA';
export const ResetUserMFA = createAction(RESET_USER_MFA);
export const RESET_USER_MFA_REQUESTED = 'RESET_USER_MFA_REQUESTED';
export const ResetUserMFARequested = createAction(RESET_USER_MFA_REQUESTED);
export const RESET_USER_MFA_FAILURE = 'RESET_USER_MFA_FAILURE';
export const ResetUserMFAFailure = createAction(RESET_USER_MFA_FAILURE);
export const RESET_USER_MFA_SUCCESS = 'RESET_USER_MFA_SUCCESS';
export const ResetUserMFASuccess = createAction(RESET_USER_MFA_SUCCESS);

// GetOrganisations
export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
export const GetOrganisations = createAction(GET_ORGANISATIONS);
export const GET_ORGANISATIONS_REQUESTED = 'GET_ORGANISATIONS_REQUESTED';
export const GetOrganisationsRequested = createAction(GET_ORGANISATIONS_REQUESTED);
export const GET_ORGANISATIONS_FAILURE = 'GET_ORGANISATIONS_FAILURE';
export const GetOrganisationsFailure = createAction(GET_ORGANISATIONS_FAILURE);
export const GET_ORGANISATIONS_SUCCESS = 'GET_ORGANISATIONS_SUCCESS';
export const GetOrganisationsSuccess = createAction(GET_ORGANISATIONS_SUCCESS);

// AddOrganisation
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const AddOrganisation = createAction(ADD_ORGANISATION);
export const ADD_ORGANISATION_REQUESTED = 'ADD_ORGANISATION_REQUESTED';
export const AddOrganisationRequested = createAction(ADD_ORGANISATION_REQUESTED);
export const ADD_ORGANISATION_FAILURE = 'ADD_ORGANISATION_FAILURE';
export const AddOrganisationFailure = createAction(ADD_ORGANISATION_FAILURE);
export const ADD_ORGANISATION_SUCCESS = 'ADD_ORGANISATION_SUCCESS';
export const AddOrganisationSuccess = createAction(ADD_ORGANISATION_SUCCESS);

// UpdateOrganisation
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const UpdateOrganisation = createAction(UPDATE_ORGANISATION);
export const UPDATE_ORGANISATION_REQUESTED = 'UPDATE_ORGANISATION_REQUESTED';
export const UpdateOrganisationRequested = createAction(UPDATE_ORGANISATION_REQUESTED);
export const UPDATE_ORGANISATION_FAILURE = 'UPDATE_ORGANISATION_FAILURE';
export const UpdateOrganisationFailure = createAction(UPDATE_ORGANISATION_FAILURE);
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UpdateOrganisationSuccess = createAction(UPDATE_ORGANISATION_SUCCESS);

// RemoveOrganisation
export const REMOVE_ORGANISATION = 'REMOVE_ORGANISATION';
export const RemoveOrganisation = createAction(REMOVE_ORGANISATION);
export const REMOVE_ORGANISATION_REQUESTED = 'REMOVE_ORGANISATION_REQUESTED';
export const RemoveOrganisationRequested = createAction(REMOVE_ORGANISATION_REQUESTED);
export const REMOVE_ORGANISATION_FAILURE = 'REMOVE_ORGANISATION_FAILURE';
export const RemoveOrganisationFailure = createAction(REMOVE_ORGANISATION_FAILURE);
export const REMOVE_ORGANISATION_SUCCESS = 'REMOVE_ORGANISATION_SUCCESS';
export const RemoveOrganisationSuccess = createAction(REMOVE_ORGANISATION_SUCCESS);

// UpdateAccountOrganisation
export const UPDATE_ACCOUNT_ORGANISATION = 'UPDATE_ACCOUNT_ORGANISATION';
export const UpdateAccountOrganisation = createAction(UPDATE_ACCOUNT_ORGANISATION);
export const UPDATE_ACCOUNT_ORGANISATION_REQUESTED = 'UPDATE_ACCOUNT_ORGANISATION_REQUESTED';
export const UpdateAccountOrganisationRequested = createAction(UPDATE_ACCOUNT_ORGANISATION_REQUESTED);
export const UPDATE_ACCOUNT_ORGANISATION_FAILURE = 'UPDATE_ACCOUNT_ORGANISATION_FAILURE';
export const UpdateAccountOrganisationFailure = createAction(UPDATE_ACCOUNT_ORGANISATION_FAILURE);
export const UPDATE_ACCOUNT_ORGANISATION_SUCCESS = 'UPDATE_ACCOUNT_ORGANISATION_SUCCESS';
export const UpdateAccountOrganisationSuccess = createAction(UPDATE_ACCOUNT_ORGANISATION_SUCCESS);
