import { Formik, Field } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../form-fields";
import { useAddOrganisationMutation, useUpdateOrganisationMutation } from "@/redux/toolkit/accountSlice";
import { Toaster } from '@/components/Toast';

const AddOrganisationSchema = Yup.object({
  name: Yup.string().required("Organization name is a required field"),
  type: Yup.string().required("Type is a required field"),
  tier: Yup.string().required("Tier is a required field")
});

function AddUpdateOrganisationModal({ hide, initialValues = false }) {

  const [addOrganisation] = useAddOrganisationMutation();
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const isEditMode = Boolean(initialValues);
  const blankFormValues = {
    name: "",
    type: "",
    tier: ""
  };

  const formInitialValues = initialValues ? initialValues : blankFormValues;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async(values, actions) => {
    setIsSubmitting(true);
    let organisationDetails ={};
    try {
      if (isEditMode) {
        organisationDetails = await updateOrganisation({data: values, organisationId: initialValues.id}).unwrap();
        Toaster('Organization updated successfully.');
      } else {
        organisationDetails = await addOrganisation({data: values}).unwrap();
        Toaster('Organization added successfully.');
      }
      window.analytics.group(organisationDetails.payload && organisationDetails.payload.organisation && organisationDetails.payload.organisation.id ? organisationDetails.payload.organisation.id : '', {
        groupType: "organization",
        id: organisationDetails.payload && organisationDetails.payload.organisation && organisationDetails.payload.organisation.id ? organisationDetails.payload.organisation.id : '',
        name: organisationDetails.payload && organisationDetails.payload.organisation && organisationDetails.payload.organisation.name ? organisationDetails.payload.organisation.name : '',
        createdAt: organisationDetails.payload && organisationDetails.payload.organisation && organisationDetails.payload.organisation.createdAt ? organisationDetails.payload.organisation.createdAt : '',
        updatedAt: organisationDetails.payload && organisationDetails.payload.organisation && organisationDetails.payload.organisation.updatedAt ? organisationDetails.payload.organisation.updatedAt : '',
      });
      await actions.resetForm(blankFormValues);
      hide(true);
    } catch (error) {
      if (isEditMode) {
        Toaster('Updating organization failed.', 'error');
      } else {
        Toaster('Adding organization failed.', 'error');
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      key={
        initialValues
          ? initialValues.name
          : "AddUpdateOrganisationForm"
      }
      initialValues={formInitialValues}
      validationSchema={AddOrganisationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {isEditMode ? `Edit Organization` : `Add Organization`}
            </h5>
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1"/>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form className="form needs-validation">
              <div className="form-group mb-3">
                <div className="form-label-group mb-3">
                  <TextInput label="Name" name="name" type="text" placeholder="Name" className="form-control" />
                </div>
                <div className="form-label-group mb-3">
                  <Field as="select" name="type" className="form-control select-tier">
                    <option value={""}>--Select Company Type--</option>
                    <option value={"Customer (Brand)"}>Customer (Brand)</option>
                    <option value={"Customer (Agency/Platform)"}>Customer (Agency/Platform)</option>
                    <option value={"Reseller"}>Reseller</option>
                    <option value={"Supplier"}>Supplier</option>
                    <option value={"Staff"}>Staff</option>
                    <option value={"Investor"}>Investor</option>
                  </Field>
                </div>
                <div className="form-label-group mb-3">
                  <Field as="select" name="tier" className="form-control select-tier">
                    <option value={""}>--Select Company Tier--</option>
                    <option value={"Tier 1"}>Tier 1</option>
                    <option value={"Tier 2"}>Tier 2</option>
                    <option value={"Tier 3"}>Tier 3</option>
                    <option value={"Not applicable"}>Not applicable</option>
                  </Field>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={hide}>
              Cancel
            </button>
            <Button
              label={"Save"}
              className="btn btn-primary"
              dirty={dirty}
              errors={errors}
              isValidating={isValidating}
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Formik>
  );
}

export default AddUpdateOrganisationModal;
