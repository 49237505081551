import { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Form, Formik, Field, FieldArray } from "formik";
import { Button, TextInput } from "../form-fields";
import { useGetAccountSubscriptionsQuery, useAddAccountUserMutation } from "@/redux/toolkit/accountSlice";
import { Toaster } from '@/components/Toast';

function InviteUserModal({ hide }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const session = useSelector((state) => state.session);

  const appAccountId = useSelector((state) => state.accounts.getAccount.data?.id);
  const {data: { subscriptions }={}, isLoading, isSuccess:isFetched } = useGetAccountSubscriptionsQuery({
    appAccountId
  });
  const [addAccountUser] = useAddAccountUserMutation();

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const invitedUser = await addAccountUser({ data: values, appAccountId }).unwrap();

      window.analytics.identify( invitedUser.payload.id , { email: invitedUser.payload.email, firstName: "", lastName: "", name: "", createdAt: invitedUser.payload.createdAt, id: invitedUser.payload.id });

      let segmentData = {
        groupType: "account",
        id: session.account && session.account.id ? session.account.id : "",
        name: session.account && session.account.company ? session.account.company : "",
        type: session.account && session.account.type ? session.account.type : "",
        tier: session.account && session.account.tier ? session.account.tier : "",
        createdAt: session.account && session.account.createdAt ? session.account.createdAt : "",
      }
      if(session.account && session.account.organisation && session.account.organisation.id) {
        segmentData.parentGroupId = session.account.organisation.id;
      }
      window.analytics.group(session.account && session.account.id ? session.account.id : "", {
        ...segmentData
        },
        {
          groupId: session.account && session.account.id ? session.account.id : "",
          userId: invitedUser.payload && invitedUser.payload.id ? invitedUser.payload.id : ""
        });

      if(session.account && session.account.organisation && session.account.organisation.id) {
        window.analytics.group(session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "", {
          groupType: "organization",
          id: session.account && session.account.organisation && session.account.organisation.id ? session.account.organisation.id : "",
          name: session.account && session.account.organisation && session.account.organisation.name ? session.account.organisation.name : "",
          createdAt: session.account && session.account.organisation && session.account.organisation.createdAt ? session.account.organisation.createdAt : "",
          updatedAt: session.account && session.account.organisation && session.account.organisation.updatedAt ? session.account.organisation.updatedAt : "",
        });
      }
      hide(true);
      Toaster('User invite sent successfully.');
    } catch (error) {
      Toaster('User invite failed.', "error");
    }
    setIsSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ email: "", subscriptionIds: [], role: "user" }}
      validationSchema={Yup.object({
        email: Yup.string().email("Invalid email address.").required("Email address is a required field."),
        role: Yup.string().oneOf(["admin", "user"]).required("Role is a required field."),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit, values }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Invite user
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1"></i>
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput name="email" type="email" label="Email address" placeholder="Email address" className="form-control" />
                </div>
              </div>
              <p>Choose a role</p>
              <div className="form-group mb-3">
                <Field as="select" name="role" className="custom-select">
                  <option value={"user"}>User</option>
                  <option value={"admin"}>Admin</option>
                </Field>
              </div>

              <p>Assign subscriptions</p>
              <div className="form-group mb-0">
                {Boolean(isLoading && !isFetched) ? (
                  <div className="loader d-flex flex-column align-items-center justify-content-center my-4">
                    <p className="text-muted m-0">
                      <i className="fal fa-lg fa-spinner fa-fast-spin" aria-hidden="true"></i>
                    </p>
                  </div>
                ) : subscriptions && subscriptions.length ? (
                  <FieldArray
                    name="subscriptionIds"
                    render={(arrayHelpers) => (
                      <>
                        {subscriptions.map(({ id, seatQuota, seatRemaining, plan: { name } }) => {
                          return (
                            <div key={id} className="custom-control custom-checkbox mb-2">
                              <input
                                className="custom-control-input"
                                name="subscriptionIds"
                                id={`subscriptionIdcheckbox-${id}`}
                                type="checkbox"
                                value={id}
                                // TODO: Add this back
                                // disabled={0 === seatRemaining}
                                checked={values.subscriptionIds.includes(id)}
                                onChange={(e) => {
                                  if (e.target.checked) arrayHelpers.push(id);
                                  else {
                                    const idx = values.subscriptionIds.indexOf(id);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />{" "}
                              <label
                                className="custom-control-label"
                                htmlFor={`subscriptionIdcheckbox-${id}`}
                              >{`${name} (${seatRemaining} of ${seatQuota} available)`}</label>
                            </div>
                          );
                        })}
                      </>
                    )}
                  />
                ) : (
                      <p>No subscriptions found.</p>
                    )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Invite user"}
                className="btn btn-danger"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Sending Invite"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default InviteUserModal;
