import React from 'react';
const SearchBox = props => {
  let { query, onSearch } = props;
  return (
    <div className="navbar px-0">
      <form className="form-inline">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1"><i className="far fa-search"></i></span>
          </div>
          <input className="form-control" type="search" placeholder="Search..." aria-label="Search" value={query}
            onChange={onSearch}
            onKeyPress={e => {
              const keyCode = e.keyCode || e.which;
              if (13 === keyCode) {
                e.preventDefault();
                return false
              }
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default SearchBox;