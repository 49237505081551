import React from "react";
import { Navigate } from "react-router-dom";
import DashboardNav from "../components/parts/DashboardNav";

function Dashboard({ mapRoleToLandingPage, role, accountId, Component }) {

  document.body.className = "has-navbar-fixed";
  if (role === null && accountId === undefined) {
    return <Navigate to="/signout" />;
  }
  return (
    <>
      <DashboardNav />
      <main role="main">
        <Component />
      </main>
    </>
  );
}

export default Dashboard;
