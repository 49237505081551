import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, TextInput } from "../../form-fields";
import { useRemoveAccountMutation } from "@/redux/toolkit/accountSlice";

function DeleteAccountModal({ hide, data: { id, company } }) {
  const [removeAccount, {isSuccess}] = useRemoveAccountMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
    if(isSuccess){
      hide(true);
    }
  }, [isSuccess, hide]);
  const onSubmit = () => {
    setIsSubmitting(true);
    removeAccount({ id });
  };

  return (
    <Formik
      initialValues={{ company: "" }}
      validationSchema={Yup.object({
        company: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type correct name of the account.", function (value) {
            return company === value;
          }),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Delete account
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hide()}>
              <span aria-hidden="true">
                <i className="fal fa-times mt-1"></i>
              </span>
            </button>
          </div>
          <Form>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="alert alert-primary" role="alert">
                      <p>
                        <b>Are you sure you want to delete {company}?</b>
                      </p>
                      Deleting account {company} will also delete all associated data. This action is irreversible.
                    </div>
                    <p>Type the name of the account below to confirm:</p>
                    <div className="form-group mb-3">
                      <TextInput name="company" type="text" placeholder={company} className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-dark" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Delete permanently"}
                className="btn btn-danger"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                processingMsg="Deleting"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default DeleteAccountModal;
