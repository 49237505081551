import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';
import { Toaster } from '../../components/Toast';
import httpClient from '../../config/http-client';
import {
	GetAllUsersFailure,
	GetAllUsersRequested,
	GetAllUsersSuccess,
	GET_ALL_USERS,
	RemoveUserFailure,
	RemoveUserRequested,
	RemoveUserSuccess,
	REMOVE_USER,
	SearchAllUsersFailure,
	SearchAllUsersRequested,
	SearchAllUsersSuccess,
	SEARCH_ALL_USERS,
} from '../actions/users-actions-types';

export function* GetAllUsers({ payload: { orderby, sortby, pageIndex, itemsPerPage } }) {
	yield put(GetAllUsersRequested());
	const payload = {
		method: 'get',
		url: '/users',
		params: { orderby, sortby, pageIndex, itemsPerPage },
	};
	const { result, error } = yield call(httpClient, payload);
	if (error) {
		yield put(GetAllUsersFailure());
		Toaster('Failed to fetch all users', 'error');
	} else {
		yield put(GetAllUsersSuccess({ itemsPerPage, ...result.payload }));
	}
}

export function* SearchAllUsers({ payload: { query, orderby, sortby, pageIndex, itemsPerPage } }) {
	yield put(SearchAllUsersRequested());

	const payload = {
		method: 'get',
		url: '/users/search',
		params: { orderby, sortby, pageIndex, itemsPerPage, query },
	};
	const { result, error } = yield call(httpClient, payload);

	if (error) {
		yield put(SearchAllUsersFailure(error));
		Toaster('Failed to fetch all users.', 'error');
	} else {
		yield put(SearchAllUsersSuccess(result.payload));
	}

	return { error, result };
}

export function* RemoveUser({ payload: { id, callback } }) {
	yield put(RemoveUserRequested());
	const { result, error } = yield call(httpClient, {
		method: 'delete',
		url: `/users/${id}`,
	});

	if (error) {
		yield put(RemoveUserFailure(error));
		Toaster('Removing user failed.', 'error');
	} else {
		yield put(RemoveUserSuccess());
		Toaster('User removed successfully.');
	}
	if (callback) callback(error, result);
	return { error, result };
}

function* Users() {
	yield all([
		debounce(400, GET_ALL_USERS, GetAllUsers),
		debounce(400, SEARCH_ALL_USERS, SearchAllUsers),
		takeLatest(REMOVE_USER, RemoveUser),
	]);
}

export default Users;
