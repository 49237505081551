import {
  useGetAllSignInMethodsQuery,
  useUpdateAccountMutation,
} from "../../../redux/toolkit/accountSlice";
import { Form, Formik, FieldArray } from "formik";
import { Button } from "../../form-fields";
import { useSelector } from "react-redux";
import { signInMethodsSchema } from "./signInMethodsSchema";

const IDProviders = () => {
  // get all sign in methods
  const {
    data: { signInMethods } = {},
    isLoading,
    isError,
  } = useGetAllSignInMethodsQuery();

  // get account sign in methods from store
  const accountSignInMethods = useSelector(
    (state) => state.accounts.getAccount.data?.signinMethods
  );

  // PATCH request to update account
  const [updateAccount] = useUpdateAccountMutation();

  // get current account id from store
  const appAccountId = useSelector(
    (state) => state.accounts.getAccount.data?.id
  );

  const onSubmit = async (values) => {
    await updateAccount({
      appAccountId: appAccountId,
      data: { signinMethodIds: values.signInMethods },
    }).unwrap();
  };

  return (
    <div
      className="tab-pane fade show active"
      id="deleteaccount"
      role="tabpanel"
      aria-labelledby="deleteaccount-tab"
    >
      <section className="mb-5">
        <p className="lead">Manage ID providers</p>
        {isLoading && (
          <div>
            <i
              className="fal fa-lg fa-spinner fa-fast-spin"
              aria-hidden="true"
            ></i>
          </div>
        )}
        {isError && (
          <div className="alert alert-danger" role="alert">
            Unable to load ID providers. Please try again later.
          </div>
        )}
        {!isLoading && !isError && signInMethods?.length > 0 && (
          <Formik
            initialValues={{
              signInMethods: accountSignInMethods.map((method) => method.id),
            }}
            validationSchema={signInMethodsSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ dirty, errors, isValidating, values, isSubmitting }) => (
              <Form className="form needs-validation">
                <FieldArray
                  name="signInMethods"
                  render={(arrayHelpers) => (
                    <>
                      {signInMethods.map((method) => (
                        <div
                          key={method.id}
                          className="custom-control custom-checkbox mb-2"
                        >
                          <input
                            className="custom-control-input"
                            name="signInMethods"
                            id={`signInMethodsCheckbox-${method.id}`}
                            type="checkbox"
                            value={method.id}
                            checked={values.signInMethods.includes(method.id)}
                            onChange={(e) => {
                              if (e.target.checked)
                                arrayHelpers.push(method.id);
                              else {
                                const idx = values.signInMethods.indexOf(
                                  method.id
                                );
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />{" "}
                          <label
                            className="custom-control-label"
                            htmlFor={`signInMethodsCheckbox-${method.id}`}
                          >{`${method.name}`}</label>
                        </div>
                      ))}
                      {/* show errors */}
                      {errors.signInMethods && (
                        <div className="invalid-feedback mb-2 d-block">
                          {errors.signInMethods}
                        </div>
                      )}
                    </>
                  )}
                />
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <Button
                    label={"Save"}
                    dirty={dirty}
                    errors={errors}
                    isValidating={isValidating}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </section>
    </div>
  );
};

export default IDProviders;
