import { api } from "@/redux/toolkit/apiSlice";
import { GetAccountSuccess } from "@/redux/actions/accounts-action-types";
import { Toaster } from "@/components/Toast";

export const extendedApiSlice2 = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccounts: builder.query({
      query: ({ query, orderby, sortby, pageIndex, itemsPerPage }) => {
        if (query.length) {
          return `/manage/accounts/search?query=${query}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        } else {
          return `/manage/accounts?orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        }
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch all accounts.", "error");
        }
      },
      transformResponse: (response) => {
        return {
          Accounts: response.payload.accounts,
          MaxPageIndex: response.payload.maxPageIndex,
          MaxRecordCount: response.payload.maxRecordCount,
        };
      },
      providesTags: ["Accounts"],
    }),
    getAccount: builder.query({
      query: ({ appAccountId }) => `/manage/accounts/${appAccountId}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(GetAccountSuccess(data.account));
        } catch (err) {}
      },
      transformResponse: (response) => {
        return {
          account: response.payload.account,
          company: response.payload.account.company,
          appAccountId: response.payload.account.id,
          tier: response.payload.account.tier,
          type: response.payload.account.type,
        };
      },
      providesTags: ["Accounts"],
    }),
    removeAccount: builder.mutation({
      query: ({ id }) => ({
        url: `/manage/accounts/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Account removed successfully.");
        } catch (err) {
          Toaster("Removing account failed.", "error");
        }
      },
      invalidatesTags: ["Accounts"],
    }),
    updateAccount: builder.mutation({
      query: ({ appAccountId, data }) => ({
        url: `/manage/accounts/${appAccountId}`,
        method: "PATCH",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Account successfully updated.");
        } catch (err) {
          Toaster("Account update failed.", "error");
        }
      },
      invalidatesTags: ["Accounts"],
    }),

    addAccountContact: builder.mutation({
      query: ({ appAccountId, data }) => ({
        url: `/manage/accounts/${appAccountId}/contacts`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AccountContact"],
    }),
    removeAccountContact: builder.mutation({
      query: ({ appAccountId, appContactId }) => ({
        url: `/manage/accounts/${appAccountId}/contacts/${appContactId}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Account contact removed successfully.");
        } catch (err) {
          Toaster("Removing account contact failed.", "error");
        }
      },
      invalidatesTags: ["AccountContact"],
    }),
    updateAccountContact: builder.mutation({
      query: ({ appAccountId, appContactId, data }) => ({
        url: `/manage/accounts/${appAccountId}/contacts/${appContactId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["AccountContact"],
    }),

    getAccountUsers: builder.query({
      query: ({
        appAccountId,
        query,
        orderby,
        sortby,
        pageIndex,
        itemsPerPage,
      }) => {
        if (query.length) {
          return `/manage/accounts/${appAccountId}/users/search?query=${query}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        } else {
          return `/manage/accounts/${appAccountId}/users?orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        }
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster(`Failed to fetch account users`, "error");
        }
      },
      transformResponse: (response) => {
        return {
          AccountUsers: response.payload.users,
          MaxPageIndex: response.payload.maxPageIndex,
          MaxRecordCount: response.payload.maxRecordCount,
        };
      },
      providesTags: ["AccountUser"],
    }),
    addAccountUser: builder.mutation({
      query: ({ appAccountId, data }) => ({
        url: `/manage/accounts/${appAccountId}/users`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AccountUser"],
    }),
    updateAccountUser: builder.mutation({
      query: ({ appAccountId, AppUserId, data }) => ({
        url: `/manage/accounts/${appAccountId}/users/${AppUserId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["AccountUser"],
    }),

    getAccountSubscriptions: builder.query({
      query: ({ appAccountId }) => {
        return `/manage/accounts/${appAccountId}/subscriptions`;
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch account subscription.", "error");
        }
      },
      transformResponse: (response) => {
        return {
          subscriptions: response.payload.subscriptions,
        };
      },
      providesTags: ["AccountSubscription"],
    }),

    getAccountSubscription: builder.query({
      query: ({ appAccountId, appSubscriptionId }) => {
        return `/manage/accounts/${appAccountId}/subscriptions/${appSubscriptionId}`;
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch account subscription.", "error");
        }
      },
      transformResponse: (response) => {
        return {
          subscription: response.payload.subscription,
        };
      },
      providesTags: ["AccountSubscription"],
    }),
    getAccountSubscriptionUsers: builder.query({
      query: ({ appAccountId, appSubscriptionId }) =>
        `/manage/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users`,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch account subscription users.", "error");
        }
      },
      transformResponse: (response) => {
        return {
          subscriptionUsers: response.payload.users,
        };
      },
      providesTags: ["SubscriptionUser"],
    }),
    addAccountSubscriptionUser: builder.mutation({
      query: ({ appAccountId, userId, appSubscriptionId }) => ({
        url: `/manage/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users`,
        method: "POST",
        body: { userId },
      }),
      invalidatesTags: ["SubscriptionUser"],
    }),
    deleteAccountSubscriptionUser: builder.mutation({
      query: ({ appAccountId, appSubscriptionId, appUserId }) => ({
        url: `/manage/accounts/${appAccountId}/subscriptions/${appSubscriptionId}/users/${appUserId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubscriptionUser"],
    }),

    resetUserMFA: builder.mutation({
      query: ({ userSuperAdmin, appAccountId, appUserId }) => ({
        url: userSuperAdmin
          ? `/manage/users/${appUserId}/mfa/reset`
          : `/manage/accounts/${appAccountId}/users/${appUserId}/mfa/reset`,
        method: "POST",
      }),
      invalidatesTags: ["AccountUser", "Users"],
    }),

    getOrganisations: builder.query({
      query: () => `/manage/organisations`,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch organizations.", "error");
        }
      },
      transformResponse: ({ payload }) => {
        let organisations = [];
        if (payload && payload.organisations && payload.organisations.length) {
          organisations = payload.organisations.sort(
            (organisation, preOrganisation) =>
              organisation.name.localeCompare(preOrganisation.name)
          );
        }
        return {
          Organisations: organisations,
        };
      },
      providesTags: ["Organisation"],
    }),
    addOrganisation: builder.mutation({
      query: ({ appAccountId, data }) => ({
        url: `/manage/organisations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Organisation"],
    }),
    removeOrganisation: builder.mutation({
      query: ({ organisationId, appUserId }) => ({
        url: `/manage/organisations/${organisationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organisation"],
    }),
    updateOrganisation: builder.mutation({
      query: ({ organisationId, data }) => ({
        url: `/manage/organisations/${organisationId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Organisation"],
    }),
    updateAccountOrganisation: builder.mutation({
      query: ({ accountId, data }) => ({
        url: `/manage/accounts/${accountId}/organisation`,
        method: "PUT",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Toaster("Account organization updated successfully.");
        } catch (err) {
          Toaster("Failed to update account organization.", "error");
        }
      },
      invalidatesTags: ["Accounts"],
    }),
    getAccountUseCases: builder.query({
      query: ({ accountId }) => {
        return `/manage/accounts/${accountId}/usecases`;
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch all account use cases", "error");
        }
      },
      transformResponse: (response) => {
        return {
          UseCases: response.payload.useCases,
        };
      },
      providesTags: ["AccountUseCases"],
    }),
    cloneContextPack: builder.mutation({
      query: (data) => ({
        url: `/manage/contextpacks/clone`,
        method: "POST",
        body: data,
      }),
    }),
    cloneBenchmark: builder.mutation({
      query: (data) => ({
        url: `/manage/benchmarks/clone`,
        method: "POST",
        body: data,
      }),
    }),
    getAllSignInMethods: builder.query({
      query: () => `/manage/signinmethods`,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster("Failed to fetch all sign in methods.", "error");
        }
      },
      transformResponse: (response) => {
        return {
          signInMethods: response.payload.signinMethods,
        };
      },
      providesTags: ["SignInMethods"],
    }),
  }),
});

export const {
  useGetAllAccountsQuery,
  useGetAccountQuery,
  useRemoveAccountMutation,
  useUpdateAccountMutation,
  useAddAccountContactMutation,
  useRemoveAccountContactMutation,
  useUpdateAccountContactMutation,

  useGetAccountUsersQuery,
  useAddAccountUserMutation,
  useUpdateAccountUserMutation,

  useGetAccountSubscriptionsQuery,
  useGetAccountSubscriptionQuery,
  useGetAccountSubscriptionUsersQuery,
  useAddAccountSubscriptionUserMutation,
  useDeleteAccountSubscriptionUserMutation,

  useResetUserMFAMutation,
  useGetOrganisationsQuery,
  useAddOrganisationMutation,
  useRemoveOrganisationMutation,
  useUpdateOrganisationMutation,
  useUpdateAccountOrganisationMutation,

  useCloneContextPackMutation,
  useCloneBenchmarkMutation,

  useGetAccountUseCasesQuery,

  useGetAllSignInMethodsQuery,
} = extendedApiSlice2;
