import React, { useState } from "react";
import Modal from "./Modal";

const ToggleModal = ({ toggle, content, className, dialogClassName, onClickBackdrop, wrapperProps, inPortal = true }) => {
  const [isShown, setIsShown] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const hide = () => {
    setIsVisible(false);
    setTimeout(() => setIsShown(false), 150);
  };
  const show = () => {
    setIsVisible(true);
    setIsShown(true);
  };

  return (
    <>
      {toggle(show)}
      {isShown && (
        <Modal
          visible={isVisible}
          fade={true}
          className={className}
          dialogClassName={dialogClassName}
          onClickBackdrop={onClickBackdrop}
          wrapperProps={wrapperProps}
          inPortal={inPortal}
        >
          {content(hide)}
        </Modal>
      )}
    </>
  );
};

export default ToggleModal;
