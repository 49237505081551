import React from "react";

const SubHeader = ({ children }) => {
  return (
    <section className="bg-light">
      <header className="container py-5">
        {children}
      </header>
    </section>
  )
}

export default SubHeader;